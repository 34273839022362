import { i18nFunctions } from "."
import { i18nMarkdownCompiler, i18nMarkdownCompilerOptions, i18nSettingsMarkdownObject } from "../types/i18n"

export default <Output = any>(i18n: i18nFunctions, id: string, text: string): Output[] => {
  if(typeof i18n.getMarkdownCompiler() === "undefined") return [ text as any ]

    let outputIndexes: i18nSettingsMarkdownObject = {}

    outputIndexes = i18n.extractStringIndexes(text, outputIndexes, /\*\*(.*?)\*\*/g, (match, children) => {
      const text = match[1]
      const bold = true
      if(typeof children !== "undefined") {
        children.text = text
        children.bold = bold
        return children
      }
      return { text, bold, options: {} }
    })

    outputIndexes = i18n.extractStringIndexes(text, outputIndexes, /\[(.*?)\]\((.*?)\)(\{.*?\})?/g, (match, children) => {
      const text = match[1]
      const link = match[2]
      let options: i18nMarkdownCompilerOptions = {}
      if(typeof match[3] !== "undefined") {
        options = match[3].slice(1, -1).split(",").reduce<i18nMarkdownCompilerOptions>((result, current) => {
          const split = current.split(":")
          result[split[0]] = split[1]
          return result
        }, {})
      }
      if(typeof children !== "undefined") {
        children.text = text
        children.link = link
        children.options = Object.assign(children.options, options)
        return children
      }
      return { text, link, options }
    })

    let lastChar = 0

    const output = (Object.keys(outputIndexes) as any[]).reduce((result, index: number) => {
      if(lastChar < index) result.push(text.slice(lastChar, index))
      const currentIndex = outputIndexes[index]
      let currentOutput: Output = currentIndex.value.text as any
      if(typeof currentIndex.value.link !== "undefined") {
        const linkCompiler = (i18n.getMarkdownCompiler() as i18nMarkdownCompiler<Output>).link
        currentOutput = linkCompiler(`${id}-${index}-link`, currentIndex.value.link, currentOutput, currentIndex.value.options)
      }
      if(typeof currentIndex.value.bold !== "undefined" && currentIndex.value.bold) {
        const boldCompiler = (i18n.getMarkdownCompiler() as i18nMarkdownCompiler<Output>).bold
        currentOutput = boldCompiler(`${id}-${index}-bold`, currentOutput, currentIndex.value.options)
      }
      result.push(currentOutput)
      lastChar = currentIndex.end
      return result
    }, [])

    if(lastChar < text.length) {
      output.push(text.slice(lastChar))
    }

    return output
}
