import RefType from "./enum"
import { Ref } from "../types/refs"
import handlers, { Handler } from "../handlers"
import refs from "../helpers/refs"
import defined from "../utils/defined"

const handler = (
  element: HTMLElement,
  self: Ref,
  updatable: boolean = true,
): Ref<any> => {
  const create = () => {
    self.type = RefType.HANDLER
    self.create = create

    const updates: { [id: number]: (() => void)[] } = {}
    const removes: (() => void)[] = []

    self.render = () => {
      return self.reduce<Promise<boolean>>((promise, handler) => promise.then(stop => {
        //console.log("HANDLER", handler.getValue(true), stop)
        if(!stop) {
          stop = handler.data === "stop"
          if(!stop) {
            return handlers(handler)(
              element,
              handler,
              refs.root(self.id).id,
              self.id,
              updatable,
            )().then(result => {
              result.ids.forEach((id) => {
                if(!defined(updates[id])) updates[id] = []
                updates[id].push(result.update)
              })
              removes.push(result.remove)
              return !!result.stop
            })
          }
        }
        return stop
      }), Promise.resolve(false))
    }

    let timeout

    self.update = id => {
      if(defined(updates[id])) {
        Promise.all(updates[id].map(cb => cb())).then(() => {
          if(defined(timeout)) clearTimeout(timeout)
          timeout = setTimeout(() => { // TODO : goodification required
            refs.clean()
          }, 3000)
        })
      }
    }

    self.remove = () => {
      if(defined(timeout)) clearTimeout(timeout)
      removes.forEach(cb => cb())
    }

    return self
  }
  return create()
}

export default handler
