
export enum TextLanguage {
  ENGLISH = "en",
  FRENCH = "fr",
}

export enum TextArticle {
  FR_MAS, // Français - Masculin (un, le, du, au, les, des, aux)
  FR_MAS_CNT, // Français - Masculin contracté (un, l', de l', à l', les, des, aux)
  FR_FEM, // Français - Féminin (une, la, de la, à la, les, des, aux)
  FR_FEM_CNT, // Français - Féminin contracté (une, l', de l', à l', les, des, aux)
}

export type TextPrimitive = string | number | (string|number)[]

export type TextByNumber<Primitive = TextPrimitive> = {
  one?: Primitive
  many?: Primitive
  short?: Primitive
  article?: TextArticle
}

export type TextForAPerson<Primitive = TextPrimitive> = {
  firstname?: Primitive
  lastname?: Primitive
  middlename?: Primitive
}

export type TextByLanguage<Primitive = TextPrimitive> = {
  [language in TextLanguage]?:
    |Primitive
    | TextByNumber<Primitive>
    | TextForAPerson<Primitive>
}

export type Text<Primitive = TextPrimitive> =
  | Primitive
  | TextByNumber<Primitive>
  | TextForAPerson<Primitive>
  | TextByLanguage<Primitive>
