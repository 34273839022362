import i18nCompileMarkdown from "./markdown"
import translate from "./translate"
import { TextLanguage } from "../types/text"
import { i18nData, i18nMarkdownCompiler, i18nOptions, i18nSettingsCompilerCallback, i18nSettingsMarkdownObject } from "../types/i18n"

export type i18nFunctions = {
  getDefaultLanguage: () => TextLanguage
  getCurrentLanguage: () => TextLanguage
  getMarkdownCompiler: () => i18nMarkdownCompiler
  setCurrentLanguage: (language: TextLanguage) => void
  setMarkdownCompiler: <Output>(compiler: i18nMarkdownCompiler<Output>) => void
  setCurrentLanguageFromString: (locale: string) => void
  extractStringIndexes: (
    text: string,
    indexes: i18nSettingsMarkdownObject,
    regex: RegExp,
    compiler: i18nSettingsCompilerCallback,
  ) => i18nSettingsMarkdownObject
  compileMarkdown: <Output = any>(id: string, text: string) => Output[]
  translate: (data: i18nData, options: i18nOptions) => string
}

export default () => {
  let defaultLanguage: TextLanguage = TextLanguage.ENGLISH
  let markdownCompiler: i18nMarkdownCompiler

  if(navigator.language) {
    defaultLanguage = navigator.language.slice(0, 2) as TextLanguage
  } else if((navigator as any).userLanguage) {
    defaultLanguage = (navigator as any).userLanguage.slice(0, 2) as TextLanguage
  }

  let currentLanguage = defaultLanguage

  const functions: i18nFunctions = {
    getDefaultLanguage: () => defaultLanguage,
    getCurrentLanguage: () => currentLanguage,
    getMarkdownCompiler: () => markdownCompiler,

    setCurrentLanguage: language => {
      currentLanguage = language
    },
    setMarkdownCompiler: compiler => {
      markdownCompiler = compiler
    },

    setCurrentLanguageFromString: locale => {
      switch(locale) {
        case TextLanguage.FRENCH: return functions.setCurrentLanguage(TextLanguage.FRENCH)
        default: return functions.setCurrentLanguage(defaultLanguage)
      }
    },

    extractStringIndexes: (text, indexes, regex, compiler) => {
      let match: RegExpExecArray
      while((match = regex.exec(text) as any) !== null) {
        const objectIndex = (Object.keys(indexes) as any[]).reduce<number>((result, index: number) => {
          if(result === -1 && match.index >= index && match.index < indexes[index].end) {
            result = index
          }
          return result
        }, -1)

        if(objectIndex === -1) {
          indexes[match.index] = { end: match.index + match[0].length, value: compiler(match) }
        } else {
          indexes[objectIndex].value = compiler(match, indexes[objectIndex].value)
        }
      }
      return indexes
    },

    compileMarkdown: (id, text) => i18nCompileMarkdown(functions, id, text),

    translate: (data, options = {}) => translate(functions, data, options),
  }

  return functions
}
