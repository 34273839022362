import setters from "."
import value from "../refs/value"
import { Ref } from "../types/refs"

export default (data: Ref): Ref => {
  const result = data.reduce((previous, current) => {
    return previous && setters(current).getValue()
  }, true)

  return value(result)
}
