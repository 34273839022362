import { Ref } from "../types/refs"
import refs from "../helpers/refs"
import RefType from "./enum"
import defined from "../utils/defined"

export default (
  specs: {
    name: string
    maxAge?: number
  },
): Ref => {
  const create = () => {
    let data = document.cookie.match('(^|;)\\s*' + specs.name + '\\s*=\\s*([^;]+)')?.pop() || ''
    const self: Ref =  {
      create,
      type: RefType.COOKIE,
      data,

      set: (_, val) => {
        const cookie = [
          `${specs.name}=${val.getValue()}`,
          `domain=.${location.host.split(":")[0].split(".").slice(-2).join(".")}`,
          `samesite=strict`,
          //`secure`,
        ]
        if(defined(specs.maxAge)) {
          cookie.push(`max-age=${specs.maxAge}`)
        }
        document.cookie = cookie.join("; ")
        data = val.getValue()
        refs.update(self.id)
        return self.id
      },

      get: () => self,

      getValue: () => data,
    }

    return self
  }
  return create()
}
