import addition from "./addition"
import addPeriod from "./addPeriod"
import and from "./and"
import atLeast from "./atLeast"
import atMost from "./atMost"
import copy from "./copy"
import dayOfMonth from "./dayOfMonth"
import differentFrom from "./differentFrom"
import equalsTo from "./equalsTo"
import exists from "./exists"
import firstDayOfMonth from "./firstDayOfMonth"
import firstDayOfWeek from "./firstDayOfWeek"
import hasKey from "./hasKey"
import hoursOfDate from "./hoursOfDate"
import join from "./join"
import lastDayOfMonth from "./lastDayOfMonth"
import lastDayOfWeek from "./lastDayOfWeek"
import lessThan from "./lessThan"
import match from "./match"
import minutesOfDate from "./minutesOfDate"
import length from "./length"
import monthOfDate from "./monthOfDate"
import moreThan from "./moreThan"
import multiplication from "./multiplication"
import not from "./not"
import now from "./now"
import objectValue from "./objectValue"
import or from "./or"
import subtraction from "./subtraction"
import subtractPeriod from "./subtractPeriod"
import yearOfDate from "./yearOfDate"
import { Ref } from "../types/refs"

export default (data: Ref): Ref => {
  const operation = data.keys()[0]
  const result = {
    addition,
    addPeriod,
    and,
    atLeast,
    atMost,
    copy,
    dayOfMonth,
    differentFrom,
    equalsTo,
    exists,
    firstDayOfMonth,
    firstDayOfWeek,
    hasKey,
    hoursOfDate,
    join,
    lastDayOfMonth,
    lastDayOfWeek,
    length,
    lessThan,
    match,
    minutesOfDate,
    monthOfDate,
    moreThan,
    multiplication,
    not,
    now,
    objectValue,
    or,
    subtraction,
    subtractPeriod,
    yearOfDate,
  }[operation](data.get(operation))
  //console.log("SET", "!!!", operation, result.getValue())
  return result
}

/*return (() => {
  switch(operation) {
    case "equalsTo": return equalsTo
    case "now": return now
  }
})()(data[operation])*/




/*
    const key = Object.keys(recipe)[0]
    switch(key) {
      case "not":
        return !condition(ctx, refs, recipe[key], bind)
      case "or":
        return recipe[key].reduce((result, or) => {
          return result || condition(ctx, refs, or, bind)
        }, false)
      case "and":
        return recipe[key].reduce((result, and) => {
          return result && condition(ctx, refs, and, bind)
        }, true)
      case "exists":
        const val = refs.value({ $: recipe[key] }, false)
        if(bind) bind(val.bind)
        const data = val.get()
        return !!data//typeof data !== "undefined"
    }
    const keyValue = refs.value( { $: key }, false)
    if(bind) bind(keyValue.bind)
    const keyData = keyValue.get()
    const operator = Object.keys(recipe[key])[0]
    const operatorValue = refs.value(recipe[key][operator], false)
    if(bind) bind(operatorValue.bind)
    const operatorData = operatorValue.get()
    switch(operator) {
      case "equalsTo":
        return keyData == operatorData
      case "atLeast": return keyData >= operatorData
      case "moreThan": return keyData > operatorData
      case "lessThan": return keyData < operatorData
      case "atMost": return keyData <= operatorData
    }
  }
*/




  /*if(operation === "now") return refs(now(), root)
  if(operation === "join") return refs(join(data[operation]), root)*/

/*const val = refs.value(currentValue[operation], false).get()
//console.log("!!", data[setter], val)
switch(operation) {
  case "addPeriod": return addPeriod(refs, val)
  case "dayOfMonth": return dayOfMonth(val)
  case "firstDayOfMonth": return firstDayOfMonth(val)
  case "firstDayOfWeek": return firstDayOfWeek(val)
  case "lastDayOfMonth": return lastDayOfMonth(val)
  case "lastDayOfWeek": return lastDayOfWeek(val)
  case "monthOfDate": return monthOfDate(ctx, val)
  case "subtractPeriod": return subtractPeriod(refs, val)
  case "yearOfDate": return yearOfDate(val)
}*/
