import app from "../helpers/app"
import parse from "../helpers/parse"
import refs from "../helpers/refs"
import request from "../utils/request"
import { HandlerResult } from "."
import { Ref } from "../types/refs"
import defined from "../utils/defined"

export default (
  element: HTMLElement,
  handlerRef: Ref,
  root: number,
  handlers: number,
  updatable: boolean,
): HandlerResult => {
  const update = () => {
    const connector = app.recipes().connectors[handlerRef.get("connector.id").getValue()]

    const httpRequestRef = handlerRef.get("connector.http.request")

    // bind getters
    let ids = new Set()
    if(updatable) {
      ids = refs.bindGetters(httpRequestRef.id, handlers)
    }

    const httpRequest = httpRequestRef.getValue()

    const host = app.recipes().hosts[httpRequest.host]

    let urlParams = ""
    if(defined(httpRequest.params)) {
      urlParams = "?" + Object.keys(httpRequest.params).reduce<string[]>((all, current) => {
        all.push(`${current}=${encodeURIComponent(httpRequest.params[current])}`)
        return all
      }, []).join("&")
    }

    let token
    if(defined(httpRequest.auth) && defined(httpRequest.auth.bearer)) {
      token = httpRequest.auth.bearer
    }

    return request(
      connector.specs.http.request.method,
      `http${host.unsecure ? "" : "s"}://${host.address}`
        + `${host.port ? `:${host.port}` : ""}${host.prefix || ""}`
        + connector.specs.http.request.path + urlParams,
      httpRequest.body,
      token,
    ).then(res => {
      const body = handlerRef.get("connector.http.response.body")
      if(body) {
        const options = refs.root(root).get("options")
        const parsed = parse(res, options.id)
        const id = options.set(body.getValue(), refs.get(parsed))
        //DEBUG.id.push(...refs.get(id).data)
      }
      return { remove: () => {}, update, ids }
    })
  }
  return update
}

/*
  const connector = ctx.recipes.connectors[id].specs.http.request

  const method = refs.value(connector.method, false).get()

  const host = ctx.recipes.hosts[http.host]

  return request(
    method,
    `http${host.unsecure ? "" : "s"}://${host.address}`
      + `${host.port ? `:${host.port}` : ""}${host.prefix}`
      + refs.value(connector.path, false).get(),
  ).then(res => {
    if(http.response.body) {
      refs.value(http.response.body, false).set(res)
    }
  })*/