import element from "../refs/element"
import { Ref } from "../types/refs"

export default (
  parent: HTMLElement,
  data: Ref,
  options: number,
) => {
  return element<HTMLInputElement>(parent, data, "input", (input, self, handleOptions) => {
    return handleOptions({
      round: round => input.type = !!round ? "radio" : "checkbox",
      checked: checked => input.checked = !!checked,
    })
  }, options)
}
