import setters from "../setters"
import RefType from "./enum"
import object from "./object"
import parse from "../helpers/parse"
import refs from "../helpers/refs"
import { Ref } from "../types/refs"

const setter = (data: Ref): Ref<any> => {
  const self = data
  self.type = RefType.SETTER
  self.create = (value) => {
    if(value) {
      return setters(self.get("'<>'"))
    }
    return setter(object())
  }

  self.getValue = (wrap) => {
    //logger.add('wtf', "setter getValue", { id: self.id, wrap }, undefined, true)
    if(wrap) {
      const result = refs.get(self.data["<>"]).getValue(true)
      // TODO: if value have children -> refs.remove(value.id)
      // ou garbage collector
      return result
    }
    // refs.set(self.id, value, false)
    const value = setters(self.get("'<>'"))
    return value.getValue()
  }

  return self
}

export default setter


/*self.clone = (id) => {
  const v = setter(data)
  debugger
  v.id = id
  return v
}*/
