import app from "../helpers/app"
import value from "../refs/value"
import { Ref } from "../types/refs"

export default (data: Ref): Ref => {
  const date = new Date(data.getValue())
  if(isNaN(date.getTime())) return value("")
  return value([
    { fr: "janvier", en: "january" },
    { fr: "février", en: "february" },
    { fr: "mars", en: "march" },
    { fr: "avril", en: "april" },
    { fr: "mai", en: "may" },
    { fr: "juin", en: "june" },
    { fr: "juillet", en: "july" },
    { fr: "août", en: "august" },
    { fr: "septembre", en: "september" },
    { fr: "octobre", en: "october" },
    { fr: "novembre", en: "november" },
    { fr: "décembre", en: "december" },
  ][date.getMonth()][app.i18n.getCurrentLanguage()])
}
