import children from "./children"
import connector from "./connector"
import handlerIf from "./if"
import handlerRange from "./range"
import handlerSet from "./set"
import handlerUnset from "./unset"
import handlerIterate from "./iterate"
import handlerAppend from "./append"
import store from "./store"
import handlerNavigation from "./navigation"
import { Ref } from "../types/refs"
import splice from "./splice"
import handlerPrint from "./print"
import objectSet from "./objectSet"

export type HandlerUpdates = {
  stop?: boolean,
  remove: () => void
  update: () => void
  ids: any
}

export type HandlerResult = () => Promise<HandlerUpdates>

export type Handler = (
  element: HTMLElement,
  handler: Ref,
  root: number,
  handlers: number,
  updatable: boolean,
) => HandlerResult

export default (
  handler: Ref,
): Handler => {
  //const loggerItems = []

  if(handler.data.children) {
    //loggerItems.push({ name: "children", value: handler.getValue(true) })
    // return children // (element, handler, refs.root(handler.id).id)
    return children
  }

  if(handler.data.connector) {
    return connector
  }

  if(handler.data.if) {
    //loggerItems.push({ name: "if", value: handler.getValue(true) })
    return handlerIf
  }

  if(handler.data.range) {
    //loggerItems.push({ name: "range", value: handler.getValue(true) })
    return handlerRange
  }

  if(handler.data.set) {
    return handlerSet
    //loggerItems.push({ name: "set", value: handler.getValue(true) })
  }

  if(handler.data.unset) {
    return handlerUnset
  }

  if(handler.data.store) return store

  if(handler.data.iterate) return handlerIterate

  if(handler.data.append) return handlerAppend

  if(handler.data.navigation) return handlerNavigation

  if(handler.data.splice) return splice

  if(handler.data.print) return handlerPrint

  if(handler.data.objectSet) return objectSet

  return () => () => Promise.resolve({ ids: [], update: () => {}, remove: () => {} })
}

/*DEV_ONLY[*console.log("HAND\texecute\t", data.id, data.data)/*]*/
/*if(!data.data) return Promise.resolve([])
return data.reduce<Promise<Ref[]>>((promise, handler) => promise.then(all => {*/
/*
  return all
}), Promise.resolve([])).then(result => {
  logger.add("handlers", `Handlers`, undefined, loggerItems)
  return result
})*/
