import value from "../refs/value"
import { Ref } from "../types/refs"
import defined from "../utils/defined"

export default (data: Ref): Ref => {
  const input = data.get("input").getValue()
  const val = data.get("value").getValue()
  //console.log("hasKey", new Date(val), defined(input[val]))
  return value(defined(input[val]))
}
