import app from "./helpers/app"
import api from "./helpers/api"
import refs from "./helpers/refs"

/*DEV_ONLY[* /
//window.onerror = (e, i) => {
//  console.error(e)
//  logger.add("error", "JS ERROR", e.toString(), undefined, true)
//}

//window.addEventListener("error", console.log)

import logger from "./helpers/logger"
logger.init()
/*]*/

//export const DEBUG = { id: [] }

;(window as any).dropin = {
  app,
  api,
  refs,
  //DEBUG,
  /*DEV_ONLY[* /logger,/*]*/
}


/*setInterval(() => {
  logger.timerResults()
}, 1000)*/


/*]* /
if(app) {
  api(request => request("GET", "/recipes.v1.json"), ({ body }) => {
    app.init(body)
    app.render()
  })
}
/**/







/** /
if(app) {
  const loadingElement = document.getElementById("loading")

  loadingElement.style.display = "inherit"

  api(request => request("GET", "/recipes.v1.json"), ({ body }) => {
    loadingElement.style.display = ""

    body.recipes.components[":pages/Catalog"] = {
      options: { a: "hello", b: { $: "options.a" } },
      children: [
        { Text: { content: { $: "options.test" } } },
        {
          Button: {
            content: "update",
            on: {
              press: [
                { set: { "options.test": "c" } },
              ],
            },
          },
        },
        //{ Text: { content: { $: "app.global" } } },
        //{ Input: { ref: "test" } },
        //{ ":Test1": { a: { $: "options.test" } } },
      ],
    } as any

    body.recipes.components[":Test1"] = {
      options: { a: "2" },
      children: [
        { Text: { content: { $: "options.a" } } },
        {
          Button: {
            content: "update",
            on: {
              press: [
                { set: { "options.test": "c" } },
              ],
            },
          },
        },
      ],
    } as any

    app.init(body.recipes)

    const route = Object.keys(body.recipes.pages).reduce<string>((found, key) => {
      if(typeof found === "undefined") {
        if(body.recipes.pages[key].path === window.location.pathname) {
          found = key
        }
      }
      return found
    }, undefined)

    app.render(route)
  })
}





/*request("GET", "http://web.apps.dropin.local:8080/recipes.v1.json").then(recipes => {
})*/

/*api(request => request("GET", "/v0/apps/web"), response => {
})*/
/*if("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/sw.js").then(() => {
    console.info("[Service Worker] Registered")
  }).catch(error => {
    console.error("[Service Worker] Error" + error)
  })
}*/

/*import app from "./helpers/app"
import refs from "./helpers/refs"*/


/** /
app.init({ // THIS IS AN HACK
  pages: {
    test1: { component: ":Test1", path: "/" },
  },
  components: {
    ":Test1": {
      options: { current: 2 },
      children: [
        {
          View: {
            children: [
              { Input: { option: "current" } },
            ],
          },
        },
        {
          View: {
            handlers: [
              {
                children: [
                  { Input: { option: "current" } },
                ],
              },
              {
                children: [
                  { Text: { content: { $: "options.current" } } }
                ]
              },
              {
                if: {
                  condition: {
                    equalsTo: { input: { $: "options.current" }, value: "test" }
                  },
                  then: [ { children: [ { Text: { content: "Success" } } ] } ],
                  else: [ { children: [ { Text: { content: "Not yet" } } ] } ],
                },
              },
            ]
          },
        },
      ]
    },
  }
} as any)

app.render()






/** /
app.init({ // THIS IS AN HACK
  pages: {
    test1: { component: ":Test1", path: "/" },
    test2: { component: ":Test2", path: "/contact" },
  },
  styles: [
    "._Test-page{flex-direction:column;flex:1;margin:20px}",
    "._Calendar-normal{background-color:blue}",
    "._Calendar-louis{background-color:red}",
  ].join(""),
  components: {
    ":Calendar": {
      options: { start: 1, current: 1 },
      children: [
        {
          View: {
            styles: { ":Test": "page" },
            handlers: [
              { children: [

                { Text: { content: "current :" } },
                { Text: { content: { $: "options.current" } } },

                { Text: { content: "start :" } },
                { Text: { content: { $: "options.start" } } },

                { Text: { content: "end :" } },
                { Text: { content: { $: "options.end" } } },

                { Button: {
                  content: "+1",
                  on: { press: [
                    { set: {
                      "options.end": { "<>": { addition: [
                        { $: "options.end" },
                        1,
                      ] } },
                      //{
                        //"options.end": { "<>": {
                       // addPeriod: { date: { $: "options.end" }, seconds: 0.001 }
                      //} }
                    //}
                    } }
                  ]}
                } }
              ] },
              { range: {
                from: { $: "options.start" },
                to: { $: "options.end" },
                do: [
                  { children: [
                    { Text: {
                      styles: [{
                        condition: {
                          equalsTo: {
                            input: { $: "options.current" },
                            value: { $: "range.current" },
                          }
                        },
                        then: { ":Calendar": "louis", },
                        else: { ":Calendar": "normal", },
                      }],
                      content: { $: "range.current" },
                      on: { press: [
                        { set: { "options.current": { $: "range.current" } } },
                      ],
                    } } }
                  ] }
                ]
              } },
            ]
          }
        }
      ]
    },
    ":Test1": {
      // options: { a: 2, b: { $: "options.a" } },
      // children: [
      //    { Text: { content: { $: "app.global" } } },
      //   { Text: { content: { $: "options.b" } } },
      // ],
      options: {
        a: "coucou Ariane",
        b: "c'est bon ça marche",
        c: "bravo",
        d: 5,
        o: {
          k: "test"
        },
      },
      children: [
        {
          View: {
            styles: { ":Test": "page" },
            children: [
              //{ Text: { content: "OK" } },
              { View: { children: [
                { Text: { content: { $: "options.a" } } },
                { Input: { option: "a" } },
                { ":Test": { c: { $: "options.a" } } },
              ] } },
              { ":Calendar": { end: { $: "options.d" } } },
              { Button: { content: "SaLouisTiti", on: { press: [ { set: { "options.a": { "<>": { "now": {} } } } } ] } } },
              { Button: { content: "Ajouter des bisous", on: { press: [ { set: { "options.b": "Des bisous" } } ] } } },
              {
                View: { handlers: [
                  {
                    children: [
                      { Text: { content: "Correspondance avec 'bravo' : " } }
                    ]
                  },
                  {
                    if: {
                      condition: {
                        equalsTo: { input: { $: "options.a" }, value: { $: "options.c" } }
                      },
                      then: [ { children: [ { Text: { content: { $: "options.b" } } } ] } ],
                      else: [ { children: [ { Text: { content: "is something else", on: { press: [ { set: { "options.a": { "<>": { "now": {} } } } } ] } } } ] } ],
                    },
                  },
                ] },
              },
              { Link: { page: { name: "test2" }, children: [ { Button: { content: "Goto another page" } } ] } },
            ],
          }
        }
      ],
    },
    ":Test": {
      children: [
        { Text: { content: { en: "IT WORKS : ", fr: "CELA FONCTIONNE :" } } },
        { Text: { content: { $: "options.c" } } },
      ],
    },
    ":Test2": {
      children: [
        { Text: { content: "🍾" } },
      ],
    }
  }
} as any)

const route = Object.keys(app.recipes().pages).reduce<string>((found, key) => {
  if(typeof found === "undefined") {
    if(app.recipes().pages[key].path === window.location.pathname) {
      found = key
    }
  }
  return found
}, undefined)

app.render(route)

//refs.mermaid(47)





/*const appData = {
  global: "OK",
}
parse(appData, 0, "app")

;(window as any).dropin = {
  mermaid: (id: number) => refs.mermaid(id),
}*/



/*const pageComponent = component(
  mainElement,
  0,
  componentRecipe,
  componentOptions,
)
refs.get(0).set("page", pageComponent)

refs.dump()

//refs.mermaid(8)*/
