import recipe from "./recipe"
import elements from "../elements"
import refs from "../helpers/refs"
import list from "./list"
import { Ref } from "../types/refs"
import { ComponentRecipe } from "../types/component"
import parse from "../helpers/parse"
import RefType from "./enum"
import defined from "../utils/defined"

export default (
  element: HTMLElement,
  parent: number,
  data: ComponentRecipe = {},
  options: number,
): Ref => {
  const create = () => {
    const self = recipe(parent, RefType.COMPONENT, create)

    refs.add(self, parent, options, data.options)

    const childrenId = self.set("children", list())

    self.render = () => {
      if(!defined(data.children)) return Promise.resolve()
      return data.children.reduce<Promise<any>>((promise, child) => promise.then(() => {
        const childRef = refs.get(parse(child, self.id, undefined, true))
        const e = elements(element, self, childRef)
        refs.get(childrenId).set("+", e)
        return e.render()
      }), Promise.resolve())
    }

    self.remove = () => {
      refs.get(childrenId).iterate((value) => value.remove())
      refs.get(childrenId).data = []
    }

    return self
  }
  return create()
}




/*DEV_ONLY[*console.log("COMP\tinit\t", self.id, data)/*]*/

/*self.render = async () => {
  self.update(undefined)
  return self
}*/


//if(typeof data.children !== "undefined") {




/*} else {
  const span = document.createElement("span")
  element.appendChild(span)

  let children: Ref[]

  self.render = () => {
    return handlers(span, refs.get(parse(data.handlers, self.id, "handlers"))).then(c => {
      children = c
    })
  }

  self.update = () => {
    span.innerHTML = ""
    self.render()
  }

  self.remove = () => {
    children.forEach(c => c.remove())
    span.remove()
  }
}*/
