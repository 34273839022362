import value from "../refs/value"
import refs from "./refs"
import list from "../refs/list"
import defined from "../utils/defined"
import getter from "../refs/getter"
import setter from "../refs/setter"
import object from "../refs/object"
import app from "./app"
import cookie from "../refs/cookie"
import indexed from "../refs/indexed"

const parse = (data: any, parent: number, key?: string, skipGetterBinds?: boolean): number => {
  if(typeof data !== "object") {
    const ref = value(data)
    const id = refs.add(ref, parent, undefined, undefined, skipGetterBinds)
    if(defined(key)) {
      refs.get(parent).set(`'${key}'`, ref)
    }
    return id
  }

  if(Array.isArray(data)) {
    const ref = list()
    const id = refs.add(ref, parent, undefined, undefined, skipGetterBinds)
    data.forEach(item => parse(item, id, "+"))
    if(defined(key)) {
      refs.get(parent).set(`'${key}'`, ref)
    }
    return id
  }

  if(data === null) {
    const ref = object()
    refs.add(ref, parent, undefined, undefined, skipGetterBinds)
    if(defined(key)) refs.get(parent).set(`'${key}'`, ref)
    return ref.id
  }

  if(defined(data.$)) {
    if(defined(data.$, "string")) {
      const ref = getter(data.$, parent)
      // const id = refs.add(ref, parent, undefined, undefined, skipGetterBinds)
      if(defined(key)) refs.get(parent).set(`'${key}'`, ref)
      return ref.id
    }
    if(data.$.stores) {
      const refQuery = `collections.stores.'${data.$.stores.id}'`
      let ref = refs.get(0).get(refQuery)
      if(!defined(ref)) {
        //console.log("usage of collection getter")
        const store = app.recipes().stores[data.$.stores.id]
        if(defined(store.cookie)) {
          ref = cookie(store.cookie)
        } else if(defined(store.indexedDB)) {
          ref = indexed(store.indexedDB, data.$.stores.key, data.$.stores.ref)
        }
        refs.get(0).set(refQuery, ref)
      }
      if(defined(key)) refs.get(parent).set(`'${key}'`, getter(refQuery, parent))
      return ref.id
    }
  }

  if(defined(data["<>"])) {
    const id = refs.add(object(), parent)
    parse(data["<>"], id, "<>")
    const ref = setter(refs.get(id))
    if(defined(key)) {
      refs.get(parent).set(`'${key}'`, ref)
    }
    return ref.id
  }

  const ref = object()
  const id = refs.add(ref, parent, undefined, undefined, skipGetterBinds)
  Object.keys(data).forEach(key => {
    parse(data[key], id, key)
  })
  if(defined(key)) {
    refs.get(parent).set(`'${key}'`, ref)
  }
  return id
}

export default parse


/*
//import collection from "../refs/collection"
---
import app from "../helpers/app"
import { Ref } from "../types/refs"

const getter = (
  data: { [collection: string]: { id: string, ref: string } },
): Ref => {
  return {
    type: "collection",
    data,

    getValue: () => {
      return
    },
  }
}

export default getter
*/
