import refs from "../helpers/refs"
import element from "../refs/element"
import { Ref } from "../types/refs"
import defined from "../utils/defined"

export default (
  parent: HTMLElement,
  data: Ref,
  options: number,
) => {
  let viewTag = "div"
  const landmark = refs.get(options).get("web.landmark")
  if(defined(landmark)) viewTag = landmark.getValue()
  return element<HTMLFormElement>(parent, data, viewTag, () => {
    return {}
  }, options)
}
