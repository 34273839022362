import defined from "./defined"

export default <Response = any>(
  method: string,
  url: string,
  body?: any,
  token?: string,
): Promise<Response> => {
  //console.log("REQUEST", method, url)
  return new Promise<Response>(resolve => {
    const http = new XMLHttpRequest()
    //const hostnameSplit = window.location.hostname.split(".")
    http.open(method, url)

    if(defined(token)) {
      http.setRequestHeader("Authorization", "Bearer " + token)
    }

    //`http://${hostnameSplit[hostnameSplit.length - 2]}.${hostnameSplit[hostnameSplit.length - 1]}:8080`
    http.send(body ? JSON.stringify(body) : undefined)
    http.onreadystatechange = e => {
      if(http.readyState === 4) {
        let response = {} as Response
        if(http.response) response = JSON.parse(http.response)
        resolve(response)
      }
    }
  })
}
