import element from "../refs/element"
import app from "../helpers/app"
import refs from "../helpers/refs"
import defined from "../utils/defined"
import { Ref } from "../types/refs"

export default (
  parent: HTMLElement,
  data: Ref,
  options: number,
) => {
  return element<HTMLLinkElement>(parent, data, "a", (link) => {
    const urlRef = refs.get(options).get("url")
    if(defined(urlRef)) {
      const url = urlRef.getValue()
      link.href = url.path
      link.rel = "noopener"
      if(!defined(url.web) || !url.web.self) {
        link.target = "_blank"
      }
    } else {
      const pageRef = refs.get(options).get("page")
      if(defined(pageRef)) {
        const page = pageRef.getValue()

        let path = app.recipes().pages[page.name].path
        let query = ""
        if(page.options) {
          // TODO : search
          Object.keys(page.options).forEach(id => {
            const result = encodeURIComponent(page.options[id])
            const param = `{${id}}`
            if(path.indexOf(param) !== -1) {
              path = path.replace(param, result)
            } else {
              query += (query === "" ? "?" : "&") + id + "=" + result
            }
          })
        }
        link.href = path + query

        link.onclick = event => {
          if(event.ctrlKey || (page.web && page.web.tab)) {
            window.open(link.href)
          } else {
            app.navigation.push(page.name, page.options)
          }
          return false
        }
        if(defined(page.web) && page.web.window) {
          link.target = "_blank"
        }
      }
    }
    return {}
  }, options)
}
