import refs from "../helpers/refs"
import { HandlerResult } from "."
import { Ref } from "../types/refs"
import defined from "../utils/defined"
import RefType from "../refs/enum"

export default (
  element: HTMLElement,
  handlerRef: Ref,
  root: number,
  handlers: number,
  updatable: boolean,
): HandlerResult => {
  return () => {
    refs.get(handlerRef.data.set).iterate(((from, key) => {
      const target = refs.get(root).get(key)
      if(defined(target)) {
        const getValue = (id) => {
          if(refs.get(id).type === RefType.GETTER) {
            // TODO: dynamic keys
            return getValue(refs.root(id).get(refs.get(id).data).id)
          }
          return id
        }
        const to = getValue(target.id)
        const data = refs.clone(getValue(from.id), to, true)
        refs.get(to).set("", data)
      } else {
        const data = refs.clone(from.id, refs.alloc(), true)
        delete data.id
        refs.get(root).set(key, data)
      }
    }))
    return Promise.resolve({ remove: () => {}, update: () => {}, ids: [] })
  }
}
