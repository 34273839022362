import element from "../refs/element"
import { Ref } from "../types/refs"

export default (
  parent: HTMLElement,
  data: Ref,
  options: number,
) => {
  return element<HTMLImageElement>(parent, data, "img", (image, self, handleOptions) => {
    return handleOptions({
      source: source => image.src = source,
      transcript: transcript => image.alt = transcript,
    })
  }, options)
}
