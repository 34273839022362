import value from "../refs/value"
import { Ref } from "../types/refs"
import defined from "../utils/defined"

export default (data: Ref): Ref => {
  return value(data.getValue().reduce((all, current) => {
    if(!defined(current, "number")) {
      current = Number(current)
    }
    return Math.round((all + current + Number.EPSILON) * 100) / 100
  }, 0))
}
