import { i18nFunctions } from "."
import { TextArticle, TextByLanguage, TextByNumber, TextForAPerson, TextLanguage } from "../types/text"
import { i18nArticle, i18nData, i18nDataQuery, i18nOptions } from "../types/i18n"

const translate = (i18n: i18nFunctions, data: i18nData, options: i18nOptions = {}): string => {
  if(typeof data === "number") return data.toString() // NAME NUMBER
  if(typeof data === "string") return fromString(i18n, data, options) // NAME STRING
  if(typeof data === "object") {
    if(Array.isArray(data)) return fromArray(i18n, data, options) // ARRAY
    const dataAsQuery = data as i18nDataQuery
    if(typeof dataAsQuery.$ !== "undefined") return fromQuery(i18n, dataAsQuery, options)
    const dataAsByNumber = data as TextByNumber
    if(
      typeof dataAsByNumber.one !== "undefined"
      || typeof dataAsByNumber.many !== "undefined"
      || typeof dataAsByNumber.short !== "undefined"
    ) {
      return fromByNumber(i18n, dataAsByNumber, options)
    }
    const dataAsAPerson = data as TextForAPerson
    if(
      typeof dataAsAPerson.firstname !== "undefined"
      || typeof dataAsAPerson.lastname !== "undefined"
      || typeof dataAsAPerson.middlename !== "undefined"
    ) {
      return fromAPerson(i18n, dataAsAPerson, options)
    }
    return fromByLanguage(i18n, data as TextByLanguage, options)
  }
  return "" // FUNCTION, UNDEFINED, ...
}

const fromString = (i18n: i18nFunctions, text: string, options: i18nOptions): string => {
  // LOWERCASE
  if(typeof options.lowercase !== "undefined" && options.lowercase) {
    text = text.toLowerCase()
  }
  return text
}

const fromArray = (i18n: i18nFunctions, data: i18nData[], options: i18nOptions): string => {
  return data.map(current => translate(i18n, current, options)).join("")
}

const fromQuery = (i18n: i18nFunctions, data: i18nDataQuery, options: i18nOptions): string => {
  if(
    typeof data.$ !== "object"
    || typeof data.$.type === "undefined"
    || data.$.type !== "i18n"
    || typeof data.$.name === "undefined"
  ) {
    return ""
  }
  let queryOptions: i18nOptions
  if(typeof data.$.options === "object") {
    queryOptions = Object.assign(options, data.$.options)
  } else {
    queryOptions = options
  }
  return translate(i18n, data.$.name, queryOptions)
}

const fromByNumber = (i18n: i18nFunctions, textByNumber: TextByNumber, options: i18nOptions): string => {
  let output: string = ""

  if(typeof options.count === "undefined") {
    options.count = 1
  }

  // One
  if(options.count === 1) {
    if(typeof textByNumber.one !== "undefined") {
      output = translate(i18n, textByNumber.one, options)
    } else if(typeof textByNumber.many !== "undefined") {
      output = translate(i18n, textByNumber.many, options)
    }

  // Many
  } else {
    if(typeof textByNumber.many !== "undefined") {
      output = translate(i18n, textByNumber.many, options)
    } else if(typeof textByNumber.one !== "undefined") {
      output = translate(i18n, textByNumber.one, options)
    }
  }

  // ARTICLES
  if(typeof options.article !== "undefined" && options.article && typeof options.language !== "undefined") {
    switch(options.language) {
      case TextLanguage.FRENCH:
        switch(options.article as i18nArticle) {
          case i18nArticle.FR_IND:
            if(options.count !== 1) {
              output = "des " + output
            } else if(textByNumber.article === TextArticle.FR_FEM || textByNumber.article === TextArticle.FR_FEM_CNT) {
              output = "une " + output
            } else {
              output = "un " + output
            }
            break
          case i18nArticle.FR_DEF:
            if(options.count !== 1) {
              output = "les " + output
            } else if(textByNumber.article === TextArticle.FR_MAS_CNT || textByNumber.article === TextArticle.FR_FEM_CNT) {
              output = "l'" + output
            } else if(textByNumber.article === TextArticle.FR_FEM) {
              output = "la " + output
            } else {
              output = "le " + output
            }
            break
          case i18nArticle.FR_DEF_CNT:
            if(options.count !== 1) {
              output = "aux " + output
            } else if(textByNumber.article === TextArticle.FR_MAS_CNT || textByNumber.article === TextArticle.FR_FEM_CNT) {
              output = "à l'" + output
            } else if(textByNumber.article === TextArticle.FR_FEM) {
              output = "à la " + output
            } else {
              output = "au " + output
            }
            break
          case i18nArticle.FR_PAR:
            if(options.count !== 1) {
              output = "des " + output
            } else if(textByNumber.article === TextArticle.FR_MAS_CNT || textByNumber.article === TextArticle.FR_FEM_CNT) {
              output = "de l'" + output
            } else if(textByNumber.article === TextArticle.FR_FEM) {
              output = "de la " + output
            } else {
              output = "du " + output
            }
            break
        }
        break
    }
  }
  return translate(i18n, output, options)
}

const fromAPerson = (i18n: i18nFunctions, person: TextForAPerson, options: i18nOptions): string => {
  let output: string = ""
  if(typeof person.firstname !== "undefined") output += person.firstname
  if(typeof person.middlename !== "undefined") output += (output.length ? " " : "") + person.middlename
  if(typeof person.lastname !== "undefined") output += (output.length ? " " : "") + person.lastname
  return translate(i18n, output, options)
}

const fromByLanguage = (i18n: i18nFunctions, byLanguage: TextByLanguage, options: i18nOptions): string => {
  if(typeof options.language !== "undefined" && typeof byLanguage[options.language] !== "undefined") {
    return translate(i18n, byLanguage[options.language] as i18nData, options)
  }
  if(typeof byLanguage["*"] !== "undefined") { // Global Name text
    return translate(i18n, byLanguage["*"], options)
  }
  if(typeof byLanguage[i18n.getCurrentLanguage()] !== "undefined") { // Default language
    options.language = i18n.getCurrentLanguage()
    return translate(i18n, byLanguage[options.language] as i18nData, options)
  }
  return ""
}

export default translate
