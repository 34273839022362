import refs from "../helpers/refs"
import { Ref } from "../types/refs"
import defined from "../utils/defined"
import RefType from "./enum"
import stand from "./stand"

const getter = (data: string, parent: number) => {
  const create = () => {
    const self: Ref = {
      type: RefType.GETTER,
      data,

      create: (value) => value ? refs.root(self.id).get(self.data).create(true) : create(),

      get: (ref) => {
        let val = refs.root(self.id).get(`${self.data}${ref.length === 0 ? "" : `.${ref}`}`)
        if(!defined(val)) {
          //return stand(ref, refs.root(self.id).id)
          return
        }
        val = val.get("") // if getter of getter
        if(self.data.startsWith("range.") || self.data.startsWith("iterate.")) {
          const cloned = refs.clone(val.id, self.id)
          refs.set(self.id, cloned)
          return cloned
        }
        return val
      },

      set: (ref, value) => {
        // if(ref.length === 0) {
        //   return refs.set(self.id, value)
        // }
        if(self.data.startsWith("range.") || self.data.startsWith("iterate.")) {
          console.error("trying to set " + self.data)
          return
        }
        return refs.root(self.id).set(
          `${self.data}${ref.length === 0 ? "" : `.${ref}`}`,
          value,
        )
      },

      getValue: (wrap) => {
        if(wrap) return { $: self.data }
        const value = refs.root(self.id).get(self.data)
        if(self.data.startsWith("range.") || self.data.startsWith("iterate.")) {
          const cloned = refs.clone(value.id, self.id)
          refs.set(self.id, cloned, false)
        }
        return value.getValue()
      },

    }

    refs.add(self, parent)
    bindPointedData(self)

    return self
  }
  return create()
}

export default getter

export const replaceClones = (self: Ref) => {
  if(defined(self.iterate)) {
    self.iterate(replaceClones)
  } else if(self.type === RefType.GETTER) {
    specialDynamicKeys(self)
    if(self.data.startsWith("range.") || self.data.startsWith("iterate.")) {
      const value = refs.root(self.id).get(self.data)
      const cloned = refs.clone(value.id, self.id)
      refs.set(self.id, cloned, false)
    }
  }
}

const specialDynamicKeys = (self) => {
  let offset = 0
  while((offset = self.data.indexOf("<", offset)+1) != 0) {
    // TODO: deal with nested dynamic keys
    let end = self.data.indexOf(">", offset)
    let subQuery = self.data.substring(offset, end)
    if(subQuery.startsWith("iterate.") || subQuery.startsWith("range.")) {
      const key = refs.root(self.id).get(subQuery).getValue()
      self.data = self.data.slice(0, offset-1) + "'" + key + "'" + self.data.slice(end+1)
    }
  }
}

const bindPointedData = (self) => {
  if(!self.data.startsWith("iterate.") && !self.data.startsWith("range.") && self.data.indexOf("<") === -1) {
    let query = self.data
    let pointedAt = refs.root(self.id).get(query)
    // iterate query backward until pointed data exist
    for(; !defined(pointedAt); pointedAt = refs.root(self.id).get(query)) {
      let end: number
      if(query.slice(-1) === "'") {
        end = query.lastIndexOf("'", 1)-1
        // const key = query.substr(end + 2, query.length - 1)
      } else {
        end = Math.max(0, query.lastIndexOf("."))
      }
      query = query.substr(0, end)
    }

    // create stand for the part that doesn't exist
    if(query.length !== self.data.length) {
      const parent = pointedAt || refs.root(self.id)
      const offset = query.length !== 0 ? 1 : 0
      pointedAt = stand(self.data.substr(query.length + offset), parent.id)
    }
    refs.bind(pointedAt.id, self.id)
  }
}
