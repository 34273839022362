import value from "../refs/value"
import { Ref } from "../types/refs"

export default (data: Ref): Ref => {
  const date = new Date(data.get("date").getValue())

  if(data.data.months) {
    date.setMonth(date.getMonth() + data.get("months").getValue())
  }

  if(data.data.seconds) {
    date.setSeconds(date.getSeconds() + data.get("seconds").getValue())
  }

  return value(date.getTime())
}
