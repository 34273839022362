import element from "../refs/element"
import { Ref } from "../types/refs"

export default (
  parent: HTMLElement,
  data: Ref,
  options: number,
) => {
  return element<HTMLButtonElement>(parent, data, "button", (button, self, handleOptions) => {
    return handleOptions({
      content: content => button.innerHTML = content,
    })
  }, options)
}
