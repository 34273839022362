import refs from "../helpers/refs"
import RefType from "./enum"
import { Ref } from "../types/refs"
import defined from "../utils/defined"

const object = () => {
  const create = () => {
    const self: Ref<{ [key: string]: number }> = {
      create,
      type: RefType.OBJECT,
      data: {},

      get: (ref) => {
        if(defined(ref, "number")) return self // TODO : investiguer
        if(ref.length === 0) return self
        //if(ref.startsWith("<")) {
          // const key = ref.slice(1, -1).split(".")
          // console.error(key, refs.get(self.id).data)
          //if(key[0] === "options") {
            //console.log(key.slice(1).join("."), refs.options(refs.root(self.id).id).getValue())
            //console.log(key.slice(1).join("."), refs.options(self.id))
          //}
          //const val = self.get(refs.root(self.id).get(ref).getValue() + "")
          //if(!defined(val)) return
        //}
        let first
        let offset = 1
        if(ref.startsWith("'")) {
          first = ref.substr(1, ref.indexOf("'", 1) - 1)
          offset = 3
        } else {
          first = ref.split(".", 1)[0]
        }
        const sub = refs.get(self.data[first])
        if(!sub || first.length + offset - 1 === ref.length) {
          return sub
        }
        const rest = ref.slice(first.length + 1)
        return sub.get(rest)
      },

      set: (ref, value) => {
        if(ref.length === 0) {
          return refs.set(self.id, value)
        }

        let first
        let offset = 1
        if(ref.startsWith("'")) {
          first = ref.substr(1, ref.indexOf("'", 1) - 1)
          offset = 3
        } else {
          first = ref.split(".", 1)[0]
        }

        if(!self.data[first]) {
          const id = value.id || refs.add(value, self.id)
          self.data[first] = id
          return self.data[first]
        }

        // if(first.length + offset - 1 === ref.length) {
        //   return refs.set(self.data[first], value)
        // }

        const rest = ref.slice(first.length + offset)
        const sub = refs.get(self.data[first])
        return sub.set(rest, value)
      },

      getValue: (wrap) => {
        return Object.keys(self.data).reduce((all, current) => {
          const sub = refs.get(self.data[current])
          all[current] = sub.getValue(wrap)
          return all
        }, {})
      },

      iterate: (onItem) => {
        //console.log("OBJECT - iterate")
        const keys = Object.keys(self.data)
        const result = Array(keys.length)
        for(let i = 0; i < keys.length; i++) {
          result[i] = onItem(refs.get(self.data[keys[i]]), keys[i])
        }
        return result
        /*return Object.keys(self.data).map(key => {
          return onItem(key, refs.get(self.data[key]))
        })*/
      },


      reduce: (onItem, initialValue) => {
        return Object.keys(self.data).reduce((previousValue, key) => {
          return onItem(previousValue, refs.get(self.data[key]), key.toString())
        }, initialValue)
      },

      keys: () => {
        return Object.keys(self.data)
      }
    }

    return self
  }
  return create()
}

export default object

/*
clone: (id) => {
  const cloned = object()
  cloned.id = id
  Object.keys(self.data).forEach(key => {
    cloned.data[key] = refs.alloc()
    refs.add(
      refs.get(self.data[key]).clone(cloned.data[key]),
      cloned.id,
    )
  })
  return cloned
},
*/
