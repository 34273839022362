import defined from "../utils/defined"
import refs from "../helpers/refs"
import parse from "../helpers/parse"
import value from "../refs/value"
import { Ref } from "../types/refs"

export default (data: Ref): Ref => {
  const val = data.getValue()
  if(defined(val, "object")) {
    return refs.get(parse(val, data.id))
  }
  return value(data.getValue())
}
