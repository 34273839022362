import refs from "../helpers/refs"
import { HandlerResult } from "."
import { Ref } from "../types/refs"
import defined from "../utils/defined"

export default (
  element: HTMLElement,
  handlerRef: Ref,
  root: number,
  handlers: number,
  updatable: boolean,
): HandlerResult => {
  return () => {
    handlerRef
    const key = refs.get(handlerRef.data.unset)
    const target = refs.get(root).get(key.getValue())
    refs.remove(target.id)
    refs.update(target.id)
    return Promise.resolve({ remove: () => {}, update: () => {}, ids: [] })
  }
}
