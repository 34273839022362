
export default (element: HTMLElement) => {

  const self = {
    add: (className: string) => {
      //console.log("BEFORE", element, element.className, className)
      //if(element.className.split(" ").indexOf(className) !== -1) return
      if(element.className) {
        element.className += " " + className
      } else {
        element.className = className
      }
      //console.log("ADD", element, element.className)
      return self
    },

    remove: (className: string) => {
      element.className = element.className.split(" ").filter(current => {
        return current !== className
      }).join(" ")
      return self
    },

    move: (from: HTMLElement) => {
      const split = element.className.split(" ")
      const index = split.indexOf("handler")
      if(index === -1) {
        element.className = from.className
        from.className = ""
      } else {
        split.splice(index, 1)
        element.className = split.join(" ")
        from.className = "handler"
      }
      return self
    },
  }

  return self
}
