import app from "../helpers/app"
import element from "../refs/element"
import { Ref } from "../types/refs"
import classname from "../utils/classname"

export default (
  parent: HTMLElement,
  data: Ref,
  options: number,
) => {
  return element<HTMLDivElement>(parent, data, "div", (link) => {
    classname(parent).add("scroll-parent")
    classname(link).add("scroll-container")
    link.onscroll = app.scroll
    return {}
  }, options, () => {
    classname(parent).remove("scroll-parent")
  })
}
