import element from "../refs/element"
import { Ref } from "../types/refs"

export default (
  parent: HTMLElement,
  data: Ref,
  options: number,
) => {
  return element<HTMLFormElement>(parent, data, "form", (form) => {
    //form.action = "javascript:void(0)"
    form.action = "javascript:"
    /*form.onsubmit = e => {
      e.preventDefault()
      const PasswordCredential = (window as any).PasswordCredential
      if(PasswordCredential) {
        console.log(e.target)
        navigator.credentials.store(new PasswordCredential({
          email: "test@test.fr",
          password: "test",
        }))
      }
    }*/
    return {}
  }, options)
}
