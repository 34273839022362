import { Ref } from "../types/refs"
import refs from "../helpers/refs"
import RefType from "./enum"
import defined from "../utils/defined"
import value from "./value"

export default (
  specs: {
    name: string
    version: number
  },
  key: string,
  ref?: string,
): Ref => {
  const create = () => {
    let data

    const indexedDB: IDBFactory = window.indexedDB
      || (window as any).mozIndexedDB
      || (window as any).webkitIndexedDB
      || (window as any).msIndexedDB

    const open = indexedDB.open("dropin", specs.version)
    open.onupgradeneeded = () => {
      open.result.createObjectStore(specs.name, {
        keyPath: "key",
      })
    }
    open.onsuccess = () => {
      const response = open.result
        .transaction(specs.name, "readwrite")
        .objectStore(specs.name)
        .get(key)
      response.onsuccess = () => {
        if(defined(ref)) {
          data = ref.split(".").reduce((all, current) => {
            if(!defined(all)) return
            return all[current]
          }, response.result)
        } else {
          data = response.result.data
        }
        //console.log("GOT", data)
        refs.update(self.id)
      }
    }

    const self: Ref =  {
      create,
      type: RefType.COOKIE,
      data,

      set: (_, val) => {
        data = val.getValue()
        refs.update(self.id)
        return self.id
      },

      get: () => self,

      getValue: () => {
        //console.log("==>", data)
        return data
      },
    }

    return self
  }
  return create()
}
