import defined from "../utils/defined"
import refs from "../helpers/refs"
import object from "../refs/object"
import stand from "../refs/stand"
import value from "../refs/value"
import handler from "../refs/handler"
import classname from "../utils/classname"
import { HandlerResult } from "."
import { Ref } from "../types/refs"

/*DEV_ONLY[* /
import logger from "../helpers/logger"
/*]*/

export default (
  element: HTMLElement,
  handlerRef: Ref,
  root: number,
  handlers: number,
  updatable: boolean,
): HandlerResult => {
  return () => {
    const stepData = handlerRef.get("range.step")
    const fromData = handlerRef.get("range.from")
    const toData = handlerRef.get("range.to")

    let ids = new Set()

    if(updatable) {
      ids = refs.bindGetters(fromData.id, handlers)
      refs.bindGetters(toData.id, handlers).forEach(id => ids.add(id))
      if(defined(stepData)) {
        refs.bindGetters(stepData.id, handlers).forEach(id => ids.add(id))
      }
    }

    const container = document.createElement("div")
    classname(container).move(element).add("handler")
    element.appendChild(container)

    // TODO: only render new keys
    let keys
    const doVal = handler(container, handlerRef.get("range.do"), updatable)

    const update = () => {
      const fromValue = parseInt(fromData.getValue())
      const toValue = parseInt(toData.getValue())

      doVal.remove()

      let isDayDate = false

      let step = 1
      if(defined(stepData)) {
        const stepValue = stepData.getValue()
        if(defined(stepValue, "object")) {
          if(defined(stepValue.days)) {
            step = stepValue.days //* 86400000
            isDayDate = true
          }
        } else {
          step = stepValue
        }
      }

      keys = []
      if(isDayDate) {
        for(
          let current = new Date(fromValue);
          current.getTime() <= toValue;
          current.setDate(current.getDate() + step)
        ) {
          keys.push(current.getTime())
        }
      } else {
        for(let current = fromValue; current <= toValue; current += step) {
          keys.push(current)
        }
      }

      let keyVal = "current"
      const key = handlerRef.get("range.key")
      if(defined(key)) keyVal = key.getValue()

      const currentId = stand(`'${keyVal}'`, refs.get(0).set("range", value())).id
      /*DEV_ONLY[* /logger.add("handlers", "range", keys)/*]*/
      return keys.reduce((promise, current) => promise.then(stop => {
        //console.log("range stop", current, stop)
        if(stop) return true
        refs.set(currentId, value(current), false)
        //console.log("range", refs.get(currentId).getValue(true))
        return doVal.render()
      }), Promise.resolve(false)).then(stop => {
        stand(`range.'${keyVal}'`, 0)
        return { ids, update, remove: doVal.remove, stop }
      })
    }

    return update()
  }
}
