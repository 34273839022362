import { Ref } from "../types/refs"

export default (data: Ref): Ref => {
  /*console.log(
    "objectValue",
    data.get("key").getValue(),
    data.get("input").getValue(),
    data.get("input").get(data.get("key").getValue()).getValue(),
  )*/
  //console.log("objectValue", data.get("input").getValue(), data.get("key").getValue())
  return data.get("input").get(data.get("key").getValue())
}
