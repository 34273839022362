import refs from "../helpers/refs"
import { HandlerResult } from "."
import { Ref } from "../types/refs"
import parse from "../helpers/parse"

export default (
  element: HTMLElement,
  handlerRef: Ref,
  root: number,
  handlers: number,
  updatable: boolean,
): HandlerResult => {
  return () => {
    refs.get(handlerRef.data.append).iterate(((from, key) => {
      const previousRef = refs.root(root).get(key)
      const previous = previousRef.getValue()
      previous.push(from.getValue())
      const newOne = parse(previous, root, undefined, false)
      previousRef.set("", refs.get(newOne))
    }))
    return Promise.resolve({ remove: () => {}, update: () => {}, ids: [] })
  }
}
