import elements from "../elements"
import refs from "../helpers/refs"
import stand from "../refs/stand"
import defined from "../utils/defined"
import list from "../refs/list"
import { HandlerResult } from "."
import { Ref } from "../types/refs"

export default (
  element: HTMLElement,
  handler: Ref,
  root: number
): HandlerResult => {
  return () => {
    return handler.get("children").reduce((promise, child) => promise.then((all) => {
      let render = child.get("render")
      if(!defined(render)) {
        render = refs.get(child.set("render", list()))
      }
      let cloned = stand("+", render.id)
      cloned = refs.clone(child.id, cloned.id, false, [ "render" ])
      refs.set(cloned.id, cloned, false)

      const e = elements(element, refs.root(root), cloned)
      render.data[render.data.length - 1] = e.id

      const onPress = e.get("options.on.press")
      if(defined(onPress)) {
        onPress.getValue() // replace range... & iterate...
      }

      return e.render().then(() => {
        all.push(e.remove)
        return all
      })
    }), Promise.resolve([])).then((removes) => ({
      ids: [],
      update: () => {},
      remove: () => removes.forEach(fn => fn()),
    }))
  }
}
