import RefType from "../refs/enum"
import setters from "../setters"
import concat from "./concat"
import { Ref } from "../types/refs"
import refs from "../helpers/refs"

const styles = (ref: Ref, bind: boolean): string[] => {
  if(ref.type === RefType.LIST) {
    return ref.reduce((all, s) => {
      const condition = s.get("condition")
      if(bind) {
        refs.bindGetters(condition.id, ref.id)
      }
      const then = !!setters(condition).data
      return concat(all, styles(s.get(then ? "then" : "else"), bind))
    }, [])
  }
  return ref.reduce((all, styleShort, key) => {
    let val = styleShort.getValue()
    if(!Array.isArray(val)) val = [ val ]
    return concat(all, val.map(className => {
      return `${key.replace(/[^a-zA-Z0-9 ]/g, "_")}-${className}`
    }))
  }, [])
}

export default styles
