import refs from "../helpers/refs"
import { Ref } from "../types/refs"
import defined from "../utils/defined"
import RefType from "./enum"

const list = () => {
  const create = () => {
    const self: Ref<number[]> = {
      create,
      type: RefType.LIST,
      data: [],

      get: (ref) => {
        if(ref.length === 0) return self
        ref = ref + ""
        const first = ref.split(".", 1)[0]
        const rest = ref.slice(first.length + 1)
        const firstNumber = parseInt(first)
        if(isNaN(firstNumber) || firstNumber >= self.data.length) return
        return refs.get(self.data[first]).get(rest)
      },

      set: (ref, value) => {
        if(ref.length === 0) {
          return refs.set(self.id, value)
        }

        let first
        if(ref.startsWith("'")) {
          first = ref.substr(1, ref.indexOf("'", 1)-1)
        } else {
          first = ref.split(".", 1)[0]
        }

        if(first === "+") {
          self.data.push(value.id ? value.id : refs.add(value, self.id))
          refs.update(self.id)
        } else {
          const rest = ref.slice(first.length + 1)
          refs.get(self.data[first]).set(rest, value)
        }

        return value.id
      },

      getValue: (wrap) => {
        return self.data.map(current => refs.get(current).getValue(wrap))
      },

      iterate: (onItem) => {
        const result = Array(self.data.length)
        for(let i = 0; i < self.data.length; i++) {
          result[i] = onItem(refs.get(self.data[i]), i as any)
        }
        return result
        //return self.data.map((value, key) => onItem(refs.get(value), key as any))
      },

      reduce: (onItem, initialValue) => {
        return self.data.reduce((previousValue, currentValue, key) => {
          return onItem(previousValue, refs.get(currentValue), key.toString())
        }, initialValue)
      },

    }
    return self
  }
  return create()
}

export default list

/*clone: (id) => {
  const cloned = list()
  cloned.id = id
  self.data.forEach((item, key) => {
    cloned.data[key] = refs.alloc()
    refs.add(
      refs.get(item).clone(cloned.data[key]),
      cloned.id,
    )
  })
  return cloned
},*/
