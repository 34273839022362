import { Ref } from "../types/refs"
import refs from "../helpers/refs"
import RefType from "./enum"
import defined from "../utils/defined"

export default (): Ref => {
  const create = () => {
    let data = document.title
    const self: Ref =  {
      create,
      type: RefType.TITLE,
      data,

      set: (_, val) => {
        document.title = val.getValue()
        data = val.getValue()
        refs.update(self.id)
        return self.id
      },

      get: () => self,

      getValue: () => data,
    }

    return self
  }
  return create()
}
