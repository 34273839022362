import refs from "../helpers/refs"
import classname from "../utils/classname"
import recipe from "./recipe"
import defined from "../utils/defined"
import elements from "../elements"
import app from "../helpers/app"
import list from "./list"
import RefType from "./enum"
import styles from "../utils/styles"
import handler from "./handler"
import { Ref } from "../types/refs"
import { TextLanguage } from "../types/text"
import { ElementBindings } from "../types/element"
import object from "./object"

export default <Type extends HTMLElement>(
  parent: HTMLElement,
  data: Ref,
  tag: string,
  onInit: (
    element: Type,
    ref: Ref,
    handleOptions: (options: { [name: string]: (data: any) => void }) => ElementBindings,
  ) => ElementBindings,
  options: number,
  onRemove?: (element: Type, ref: Ref) => void,
): Ref => {
  const create = () => {
    const element = document.createElement(tag) as Type

    const self = object() // recipe(data.id, RefType.ELEMENT, create)
    self.type = RefType.ELEMENT
    self.create = create

    let dataChildren = refs.get(options).get("children")
    if(defined(dataChildren)) {
      delete refs.get(options).data.children
    }

    refs.add(self, data.id, undefined, undefined, true)
    self.set("options", refs.get(options))
    refs.get(options).iterate((value) => {
      refs.bind(value.id, self.id)
    })
    // options = refs.options(self.id).id
    let dataHandlers = refs.get(options).get("handlers")

    let previousStyles = []
    let container

    let stylesBinds = true

    let applyStyles = () => {}
    if(defined(refs.get(options).get("styles"))) {
      const currentStyles = self.get("options.styles")
      applyStyles = () => {
        const newStyles = styles(currentStyles, stylesBinds)
        stylesBinds = false
        previousStyles.forEach(s => {
          if(newStyles.indexOf(s) === -1) classname(container || element).remove(s)
        })
        newStyles.forEach(s => {
          if(previousStyles.indexOf(s) === -1) classname(container || element).add(s)
        })
        previousStyles = newStyles
      }
    }

    const unprintable = self.get("options.web.unprintable")
    if(unprintable) {
      classname(container || element).add("unprintable")
    }

    const printable = self.get("options.web.printable")
    if(printable) {
      classname(container || element).add("printable")
    }

    const bindings = onInit(container || element, self, (items) => {
      return Object.keys(items).reduce<ElementBindings>((all, current) => {
        let option: Ref
        if(current.startsWith("options.")) {
          option = refs.root(self.id).get(current)
          refs.bind(option.id, self.id)
        } else {
          option = self.get("options." + current)
        }
        if(defined(option)) all[option.id] = items[current]
        return all
      }, {})
    })

    let onPressFunction: (event: any) => void
    let onPressOutsideId: number

    const update = id => {
      bindings[id](app.i18n.translate(refs.get(id).getValue(), {
        language: TextLanguage.FRENCH,
      }))
    }

    const render = async () => {
      Object.keys(bindings).forEach(id => update(id))

      parent.appendChild(element)

      const onPress = self.get("options.on.press")
      if(onPress) {
        handler(element, onPress, false)
        onPressFunction = () => {
          onPress.render()
        }
        element.addEventListener("click", onPressFunction, false)
        classname(element).add("clickable")
      }

      const onPressOutside = self.get("options.on.pressOutside")
      if(onPressOutside) {
        handler(element, onPressOutside, false)
        onPressOutsideId = app.events.outside.add(element, () => {
          onPressOutside.render()
        })
      }
    }

    if(defined(dataChildren)) {
      self.set("options.children", list())
      self.render = () => {
        applyStyles()
        return dataChildren.reduce((promise, value) => promise.then(() => {
          const e = elements(element, data, value)
          self.set(`options.children.+`, e)
          return e.render()
        }), Promise.resolve()).then(() => {
          render()
          return false
        })
      }
      self.update = applyStyles
    } else if(defined(dataHandlers)) {
      container = document.createElement("div")
      container.className = "handler"
      self.render = () => {
        applyStyles()
        return handler(container, dataHandlers).render().then(() => {
          element.appendChild(container)
          render()
          return false
        })
      }

      self.update = (id) => {
        if(defined(bindings[id])) update(id)
        dataHandlers.update(id)
        applyStyles()
      }
    } else {
      self.update = (id) => {
        refs.lock(self.id)
        if(defined(id)) {
          if(defined(bindings[id])) update(id)
        } else {
          Object.keys(bindings).forEach(id => update(id))
        }
        applyStyles()
        refs.unlock(self.id)
      }
      self.render = () => {
        applyStyles()
        return render().then(() => {
          return false
        })
      }
    }

    self.remove = () => {
      if(defined(onRemove)) onRemove(element, self)
      if(defined(onPressFunction)) {
        element.removeEventListener("click", onPressFunction, false)
      }
      if(defined(onPressOutsideId)) {
        app.events.outside.remove(onPressOutsideId)
      }
      element.remove()
    }

    return self
  }
  return create()
}



/** /
refs.bind(dataHandlers.id, self.id, undefined, [ "children" ])
self.render = () => handlers(span, refs.get(dataHandlers.id)).then(children => {
  self.set("options.children", list())
  children.forEach(c => self.set(`options.children.+`, c))
  render()
})
self.update = () => {
  refs.lock(self.id)
  handlers(span, refs.get(dataHandlers.id)).then(children => {
    self.get("options.children").iterate((key, value) => {
      value.remove()
    })
    span.innerHTML = ""
    self.set("options.children", list())
    return children.reduce((promise, value) => promise.then(() => {
      self.set(`options.children.+`, value)
      return value.render()
    }), Promise.resolve()).then(() => {
    refs.unlock(self.id, true)
    })
  })
}
/**/





/*self.update = (id) => {
  const optionsChildren = self.get("options.children")
  // if(defined(optionsChildren)) {
  //   optionsChildren.iterate((_, value) => {
  //     const id = value.id
  //   })
  // }
  handlers(element, self.get("options.handlers")).then(children => {
    self.set("options.children", list())
    children.forEach((value) => {
      self.set(`options.children.+`, value)
    })
  })
  if(defined(bindings[id])) update(id)
  applyStyles()
}/**/



/*DEV_ONLY[*
const onClickDebug = () => {
  console.log("ELEM\tclick\t", self.id, element)
  logger.add("element", "click", self.getValue())
}
element.addEventListener("click", onClickDebug, false)
/*]*/




/*DEV_ONLY[*
//console.log("ELEM\tremove\t", self.id)
//logger.add("element", "remove", self.getValue())
element.removeEventListener("click", onClickDebug, false)
/*]*/



/*DEV_ONLY[*console.log("ELEM\trender\t", self.id, tag, options)/*]*/
