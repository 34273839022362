import refs from "../helpers/refs"
import { HandlerResult } from "."
import { Ref } from "../types/refs"
import list from "../refs/list"
import parse from "../helpers/parse"

export default (
  element: HTMLElement,
  handlerRef: Ref,
  root: number,
  handlers: number,
  updatable: boolean,
): HandlerResult => {
  return () => {
    refs.get(handlerRef.data.splice).iterate(((from, id) => {
      const array = from.get("input").getValue()
      const key = from.get("key").getValue()
      array.splice(key, 1)
      const newOne = parse(array, root, undefined, false)
      refs.root(root).get(id).set("", refs.get(newOne))
    }))
    return Promise.resolve({ remove: () => {}, update: () => {}, ids: [] })
  }
}
