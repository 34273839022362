import defined from "../utils/defined"
import object from "../refs/object"
import parse from "./parse"
import RefType from "../refs/enum"
import stand from "../refs/stand"
import { Ref } from "../types/refs"


/*DEV_ONLY[* /import logger, { LoggerItem } from "./logger"/*]*/

export default (() => {
  const refs: {
    [id: number]: {
      root: number
      data?: Ref
      observable: { [recipe: number]: number }
      observers: number[]
      options?: number
      used?: boolean
    }
  } = {}

  const locks: { [key: number]: boolean } = {}

  let count = 1

  const self = {
    locks, // FIXME: REMOVE

    init: () => {
      refs[0] = {
        data: object(),
        observable: {},
        observers: [],
        root: 0,
      }
      Object.keys(refs).forEach(key => {
        refs[key].data.id = key
      })
    },

    alloc: () => {
      //logger.add("refs", `alloc ${count}`, undefined, undefined, true)
      return count++
    },

    add: (
      data: Ref,
      parent: number,
      options?: number,
      defaultOptions: any = {},
      skipGetterBinds?: boolean,
    ): number => {
      if(!defined(data.id)) data.id = self.alloc()

      refs[data.id] = {
        data,
        //observable: Object.assign({}, refs[parent].observable),
        /*observable: Object.keys(refs[parent].observable).reduce((all, current) => {
          all[current] = refs[parent].observable[current]
          return all
        }, {}),*/
        observable: {},
        observers: [],
        root: refs[parent].root,
      }

      Object.keys(refs[parent].observable).forEach(recipe => {
        refs[data.id].observable[recipe] = refs[parent].observable[recipe]
        refs[recipe].observers.push(data.id)
      })


      if(defined(options)) {
        if(data.type !== RefType.ELEMENT) {
          refs[data.id].root = data.id
        }

        const optionsId = self.alloc()
        refs[optionsId] = {
          root: refs[parent].root,
          observable: {},
          observers: [],
        }

        refs[optionsId].data = self.clone(options, optionsId)
        Object.keys(defaultOptions).forEach((key) => {
          if(!defined(refs[optionsId].data.data[key])) {
            parse(defaultOptions[key], optionsId, key)
          }
        })

        refs[data.id].options = optionsId
        refs[optionsId].data.iterate((value) => {
          self.bind(value.id, data.id)
        })

        const bindIfGetter = (id) => {
          if(refs[id].data.type === RefType.GETTER) {
            const pointedAt = self.root(id).get(refs[id].data.data)
            if(!defined(pointedAt)) {
              // FIXME: stand(refs[id].data.data, 0)
            } else {
              self.bind(pointedAt.id, id)
            }
          } else if(defined(refs[id].data.iterate)) {
            refs[id].data.iterate((value, key) => {
              if(!skipGetterBinds || key !== "children") {
                bindIfGetter(value.id)
              }
            })
          }
        }
        bindIfGetter(optionsId)
      }

      //self.dump(data.id, "add")
      return data.id
    },

    get: (id: number) => {
      if(!refs[id]) return
      //self.dump(id, "get")
      return refs[id].data
    },

    set: (id: number, data: Ref, update: boolean = true) => {
      data.id = id
      const old = refs[id].data
      refs[id].data = data
      /*DEV_ONLY[*self.dump(id, "set")/*]*/
      const updated: { [recipe: number]: Set<string> } = {}
      if(update) {
        Object.keys(refs[id].observable).forEach(
          (recipe) => {
            if(!defined(updated[recipe])) {
              updated[recipe] = new Set()
            }
            updated[recipe].add(refs[id].observable[recipe])
          }
        )
        self.update(id) // TODO: opti, update after handlers
      }
      const bindChildren = (old, data) => {
        //console.log("SET", old.id)
        if(defined(old.iterate)) {
          old.iterate((value, key) => {
            const dataChild = data.get(key)

            if(update) {
              self.update(value.id, updated)
            }

            Object.keys(refs[value.id].observable).forEach((recipe) => {
              const target = refs[value.id].observable[recipe]
              if(!defined(updated[recipe])) {
                updated[recipe] = new Set()
              }
              updated[recipe].add(target)
              if(defined(dataChild)) {
                self.bind(dataChild.id, parseInt(recipe), target)
              }
            })

            if(defined(dataChild)) {
              bindChildren(value, dataChild)
            }
          })
        }
      }
      bindChildren(old, data)
      return id
    },

    update: (id: number, exclude?: { [recipe: number]: Set<string> }) => {
      /*DEV_ONLY[*self.dump(id, "update")/*]*/
      if(locks[id]) return
      /*DEV_ONLY[* /logger.add("update", "Update " + id, locks)/*]*/
      Object.keys(refs[id].observable).forEach(recipe => {
        if(defined(refs[recipe])) {
          if(
            defined(exclude)
            && defined(exclude[recipe])
            && exclude[recipe].has(refs[id].observable[recipe])
          ) {
            return
          }
          const recipeId = parseInt(recipe)
          /*DEV_ONLY[*self.dump(recipeId, "update recipe")/*]*/
          if(defined(refs[recipe].data.update) && !refs[recipe].data.lock) {
            refs[recipe].data.update(refs[id].observable[recipe], exclude)
          }
          self.update(recipeId, exclude)
        }
      })
    },

    remove: (id: number | string) => {
      /*DEV_ONLY[*self.dump(id, "remove")/*]*/
      //logger.add("refs", `Suppression de la ref ${id}`, refs[id].data.getValue(true), [], true)
      const observers = [...refs[id].observers]
      observers.forEach(key => {
        self.unbind(key, id)
      })
      // if(defined(refs[id].options)) {
      //   self.remove(refs[id].options)
      // }
      // if(defined(refs[id].data.iterate)) {
      //   refs[id].data.iterate((_, value) => {
      //     if(defined(value)) {
      //       self.remove(value.id)
      //     }
      //   })
      // }
      if(defined(refs[id].data.remove)) {
        refs[id].data.remove()
      }
      delete refs[id]
    },

    bind: (id: number, recipe: number, target?: number, excludeKeys: string[] = []) => {
      target = target || id
      //logger.add("refs", `Bind de ${recipe} pour observer ${id}`, { [id]: { value: refs[id].data.getValue(true) }, [recipe]: { value: refs[recipe].data.getValue(true) } }, [], true)
      if(defined(refs[id].observable[recipe])) return
      refs[id].observable[recipe] = target
      refs[recipe].observers.push(id)
      if(defined(refs[id].data.iterate)) {
        refs[id].data.iterate((value, key) => {
          if(excludeKeys.indexOf(key) === -1) {
            self.bind(value.id, recipe, target, excludeKeys)
          }
        })
      }
      // self.mermaid(id)
    },

    unbind: (id: number | string, recipe: number | string) => {
      if(defined(refs[id])) {
        delete refs[id].observable[recipe]
        if(defined(refs[id].data.iterate)) {
          refs[id].data.iterate((value, key) => {
            self.unbind(value.id, recipe)
          })
        }
      }
      const index = refs[recipe].observers.indexOf(id)
      refs[recipe].observers.splice(index, 1)
    },

    root: (id: number) => {
      if(!defined(refs[id])) {
        document.location.reload()
        return
      }
      return refs[refs[id].root].data
    },

    binds: (id: number) => refs[id].observable,

    bindGetters: (id: number, bindTo: number): any => { // FIXME: typescript Set support ?
      const ids = new Set()

      const bindGetter = (getter: Ref) => {
        const pointedAt = self.root(getter.id).get(getter.data)
        if(pointedAt.type === RefType.GETTER) {
          bindGetter(pointedAt)
        } else {
          ids.add(pointedAt.id)
          self.bind(pointedAt.id, bindTo)
        }
      }

      const handleGetters = (data: Ref) => {
        if(data.type === RefType.GETTER) {
          bindGetter(data)
        } else if(data.type === RefType.SETTER && data.get("'<>'").keys()[0] === "exists") {
          // TODO
        } else if(defined(data.iterate)) {
          data.iterate((value) => {
            handleGetters(value)
          })
        }
      }

      handleGetters(refs[id].data)

      return ids
    },

    options: (id: number): Ref => refs[refs[id].options].data,

    clone: (from: number, to: number, value?: boolean, exclude: string[] = []) => {
      /*DEV_ONLY[* /const timerStart = logger.timerStart()/*]*/
      // const current = refs[from].data
      const current = refs[from].data
      const newRef = current.create(value)
      newRef.id = to

      if(defined(current.iterate) && (!value || current.type != RefType.SETTER)) {
        current.iterate((item, key) => {
          if(exclude.indexOf(key) !== -1) return
          const itemId = self.alloc()
          refs[itemId] = {
            root: refs[to].root,
            observable: {},
            observers: [],
          }
          refs[itemId].data = self.clone(item.id, itemId, value)
          newRef.data[key] = itemId
        })
      }
      /*DEV_ONLY[* /logger.timerEnd("clone", timerStart)/*]*/
      return newRef
    },

    lock: (id: number) => {
      locks[id] = true
    },

    unlock: (id: number, clean?: boolean) => {
      delete locks[id]
      if(clean && Object.keys(locks).length === 0) {
        self.clean()
      }
    },

    mark: (id: number = 0) => {
      refs[id].used = true
      const iterate = self.get(id).iterate
      if(defined(iterate)) {
        iterate((value) => self.mark(value.id))
      }
      if(defined(refs[id].options)) {
        self.mark(refs[id].options)
      }
    },

    clean: (skipMark?: boolean) => {
      /*DEV_ONLY[* /
      self.count()
      console.time("[REFS] clean")
      /*]*/
      try {
        if(!skipMark) {
          Object.keys(refs).forEach((key) => {
            delete refs[key].used
          })
          self.mark()
        }
        Object.keys(refs).forEach((key) => {
          if(defined(refs[key]) && !refs[key].used) {
            self.remove(key)
            //console.log(key)
          }
        })
      } catch(e) {
        //window.location.reload()
        console.error(e)
      }
      /*DEV_ONLY[* /
      console.timeEnd("[REFS] clean")
      self.count()
      /*]*/
    },

    /*DEV_ONLY[* /

    count: () => {
      let totalRefs = 0
      const counts = {}
      const totalBinds = Object.values(refs).reduce((all, current) => {
        if(!defined(counts[current.data.type])) counts[current.data.type] = 0
        counts[current.data.type]++
        totalRefs++
        return all + Object.keys(current.observable).length
      }, 0)
      console.log("REFS\t count :", totalRefs, totalBinds, counts)
      return { refs: totalRefs, binds: totalBinds }
    },

    dumpValue: (id, name?) => {
      if(!defined(refs[id])) {
        return {
          name: `${name} UNDEFINED`,
          type: "error",
          children: [],
        }
      }
      const result: LoggerItem = {
        name: `${name} ${id}`,
        children: [],
        type: refs[id].data.type,
      }
      switch(refs[id].data.type) {
        case RefType.VALUE:
        case RefType.GETTER:
        case RefType.SETTER:
          result.value = refs[id].data.data
          break
      }
      if(defined(refs[id].data.iterate)) {
        refs[id].data.iterate((value, key) => {
          let id
          if(defined(value)) id = value.id
          result.children.push(self.dumpValue(id, key))
        })
      }
      return result
    },

    dump: (id: number = 0) => {
      // get components
      const components = [self.dumpValue(id, "root")]
      Object.keys(refs).forEach(id => {
        if(refs[id].data.type === "component") {
          components.push({
            name: id,
            children: [],
            type: RefType.COMPONENT,
          })
        }
      })

      let first = true
      components.forEach(compLog => {
        if(first) {
          first = false
          return
        }
        const comp = refs[compLog.name]
        const optsId = comp.options
        compLog.name = "component "+compLog.name
        const data = Object.keys(comp.data.data).reduce((all, key) => {
          all.push(self.dumpValue(comp.data.data[key], key))
          return all
        }, [self.dumpValue(optsId, "options")])
        if(data.length !== 0) {
          compLog.children.push({
            name: "data",
            children: data,
          })
        }
      })

      logger.add("dump", "Dump", undefined, components)
    },

    mermaid: (id: number) => {
      let mermaid = "graph TD\n"
      const add = (i) => {
        const data = refs[i].data.data
        const content = defined(data, "object") ? "" : ("<br>" + data)
        mermaid += `\t${i}[${i}<br>${refs[i].data.type}${content}]\n`
      }
      const link = (from, to) => mermaid += `\t${from} --> ${to}\n`

      const doneList = []
      const loop = (i) => {
        if(doneList.indexOf(i) === -1) {
          add(i)
          doneList.push(i)
          Object.keys(refs[i].observable).forEach(bi => {
            if(bi !== i) {
              link(i, bi)
              loop(bi)
            }
          })
        } else {
          console.error("Already loaded", i)
        }
      }

      Object.keys(refs).forEach(ri => {
        if(defined(refs[ri].observable[id])) {
          link(ri, id)
        }
      })

      loop(id)

      logger.mermaid(mermaid, "binds", "refs")
    },

    meta: (id) => refs[id],
    /*]*/

  }

  return self
})()



/*let mermaid = "graph TD\n"
Object.keys(refs).forEach(i => {
  mermaid += `\t${i}[${i}<br>${refs[i].data.type}]\n`
})
Object.keys(refs).forEach(i => {
  if(i != refs[i].root) {
    mermaid += `\t${i} -- root --> ${refs[i].root}\n`
  }
})
console.log("MERMAID :")
console.log(mermaid)*/

/*

children:
  - Encadre:
      children:
        - $:
            blueforest:sandbox:v1:Children:



id: :Encadre
options:
  children:
    format:
      type: component
      type: list
      format:
        type: line
        options:
          collection: components
children:
  - View:
      children: { $: options.children }



Difficulté :
[X] Facile

*/
