import value from "../refs/value"
import { Ref } from "../types/refs"

export default (data: Ref): Ref => {
  let separator = ""
  if(data.data.separator) {
    separator = data.get("separator").getValue()
  }
  return value(data.get("values").getValue().join(separator))
}
