import refs from "../helpers/refs"
import { HandlerResult } from "."
import { Ref } from "../types/refs"
import defined from "../utils/defined"
import app from "../helpers/app"

export default (
  element: HTMLElement,
  handlerRef: Ref,
  root: number,
  handlers: number,
  updatable: boolean,
): HandlerResult => {
  return () => {
    const navigation = refs.get(handlerRef.data.navigation).getValue()
    if(defined(navigation.push)) {
      if(defined(navigation.push.page)) {
        app.navigation.push(navigation.push.page.name, navigation.push.page.options)
      } else if(defined(navigation.push.url)) {
        window.location = navigation.push.url.path
      }
    }
    return Promise.resolve({ remove: () => {}, update: () => {}, ids: [] })
  }
}
