import element from "../refs/element"
import refs from "../helpers/refs"
import value from "../refs/value"
import defined from "../utils/defined"
import RefType from "../refs/enum"
import { Ref } from "../types/refs"
import handler from "../refs/handler"

export default (
  parent: HTMLElement,
  data: Ref,
  options: number,
) => {
  /*let type = "input"
  const lines = refs.get(options).getValue()
  if(defined(lines)) {}*/
  const type = defined(refs.get(options).data.lines) ? "textarea" : "input"
  let onInput: (event: any) => void
  return element<HTMLInputElement | HTMLTextAreaElement>(parent, data, type, (input, self, handleOptions) => {

    const kindRef = self.get("options.kind")
    if(defined(kindRef)) {
      const kind = kindRef.getValue()
      if(kind === "email") {
        input.setAttribute("type", "email")
        input.autocomplete = "email"
      } else if(kind === "phone") {
        input.setAttribute("type", "tel")
        input.autocomplete = "tel"
      } else if(kind === "password") {
        input.setAttribute("type", "password")
        //input.autocomplete = "current-password"
      } else {
        input.setAttribute("type", kind)
      }
    }

    const minRef = self.get("options.min")
    if(defined(minRef)) (input as any).min = minRef.getValue()

    const maxRef = self.get("options.max")
    if(defined(maxRef)) (input as any).max = maxRef.getValue()

    const minLengthRef = self.get("options.length.min")
    if(defined(minLengthRef)) input.minLength = minLengthRef.getValue()

    const maxLengthRef = self.get("options.length.max")
    if(defined(maxLengthRef)) input.maxLength = maxLengthRef.getValue()

    const inputOptions: { [name: string]: (data: any) => void } = {
      placeholder: placeholder => input.placeholder = placeholder,
      lines: lines => (input as HTMLTextAreaElement).rows = lines,
    }

    const optionRef = self.get("options.option")
    if(defined(optionRef)) {
      const option = "options." + optionRef.getValue()
      const ref = refs.root(self.id).get(option)
      input.value = ref.getValue()
      if(ref.type === RefType.COOKIE) {
        onInput = e => ref.set(undefined, value(e.target.value))
      } else {
        onInput = e => refs.root(self.id).set(option, value(e.target.value))
      }
      input.addEventListener("input", onInput, false)
      inputOptions[option] = val => input.value = val
    }

    const onChange = self.get("options.on.change")
    if(defined(onChange)) {
      handler(input, onChange, false)
      input.onchange = () => onChange.render()
    }

    const onFocus = self.get("options.on.focus")
    if(defined(onFocus)) {
      handler(input, onFocus, false)
      input.onfocus = () => {
        console.log("focus")
        onFocus.render()
      }
    }

    return handleOptions(inputOptions)
  }, options, (input) => {
    if(defined(onInput)) {
      input.removeEventListener("input", onInput, false)
    }
  })
}
