import refs from "../helpers/refs"
import { HandlerResult } from "."
import { Ref } from "../types/refs"
import parse from "../helpers/parse"
import defined from "../utils/defined"

export default (
  element: HTMLElement,
  handlerRef: Ref,
  root: number,
  handlers: number,
  updatable: boolean,
): HandlerResult => {
  return () => {
    const input = refs.get(handlerRef.data.objectSet).get("input")
    const key = refs.get(handlerRef.data.objectSet).get("key").getValue()

    const previous = input.getValue()

    const valueRef = refs.get(handlerRef.data.objectSet).get("value")
    if(defined(valueRef)) {
      previous[key] = valueRef.getValue()
    } else {
      delete previous[key]
    }

    const newOne = parse(previous, root, undefined, false)
    input.set("", refs.get(newOne))

    return Promise.resolve({ remove: () => {}, update: () => {}, ids: [] })
  }
}
