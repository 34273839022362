import refs from "../helpers/refs"
import { HandlerResult } from "."
import { Ref } from "../types/refs"
import app from "../helpers/app"
import parse from "../helpers/parse"
import defined from "../utils/defined"

export default (
  element: HTMLElement,
  handlerRef: Ref,
  root: number,
  handlers: number,
  updatable: boolean,
): HandlerResult => {
  return () => new Promise(resolve => {
    const output = { remove: () => {}, update: () => {}, ids: [] }

    refs.get(handlerRef.data.store).iterate((ref, key) => {
      const dataRef = refs.get(ref.id).get("")

      const store = app.recipes().stores[key]
      const type = Object.keys(store)[0]

      if(type === "cookie") {
        refs.get(0).get(`collections.stores.'${key}'`).set("", dataRef)
        resolve(output)
      } else if(type === "indexedDB") {
        const data = dataRef.getValue(true)
        const mode = Object.keys(data)[0]

        const indexedDB: IDBFactory = window.indexedDB
          || (window as any).mozIndexedDB
          || (window as any).webkitIndexedDB
          || (window as any).msIndexedDB

        const open = indexedDB.open("dropin", store[type].version)
        open.onupgradeneeded = () => {
          open.result.createObjectStore(store[type].name, {
            keyPath: "key",
          })
        }

        open.onsuccess = () => {
          if(mode === "get") {
            const response = open.result
              .transaction(store[type].name, "readonly")
              .objectStore(store[type].name)
              .get(data[mode].key)
            response.onsuccess = () => {
              if(defined(response.result)) {
                const id = parse(response.result.data, handlers, undefined, true)
                refs.get(root).set("options." + data[mode].option, refs.get(id))
              }
              resolve(output)
            }
          } else if(mode === "set") {
            const dataKey = Object.keys(data[mode])[0]
            const id = parse(data[mode][dataKey], root, undefined, true)
            const response = open.result
              .transaction(store[type].name, "readwrite")
              .objectStore(store[type].name)
              .put({ key: dataKey, data: refs.get(id).getValue() })
            response.onsuccess = () => {
              resolve(output)
            }
          } else if(mode === "delete") {
            const response = open.result
              .transaction(store[type].name, "readwrite")
              .objectStore(store[type].name)
              .delete(data[mode])
            response.onsuccess = () => {
              resolve(output)
            }
          }
        }
      }
    })
  })
}
