import value from "../refs/value"
import { Ref } from "../types/refs"

export default (data: Ref): Ref => {
  const date = new Date(data.getValue())
  return value(new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
  ).getTime())
}
