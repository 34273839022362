
enum RefType {
  COMPONENT/*DEV_ONLY[* / = "component" /*]*/,
  COOKIE/*DEV_ONLY[* / = "cookie" /*]*/,
  ELEMENT/*DEV_ONLY[* / = "element" /*]*/,
  GETTER/*DEV_ONLY[* / = "getter" /*]*/,
  HANDLER/*DEV_ONLY[* / = "handler" /*]*/,
  LIST/*DEV_ONLY[* / = "list" /*]*/,
  OBJECT/*DEV_ONLY[* / = "object" /*]*/,
  SETTER/*DEV_ONLY[* / = "setter" /*]*/,
  STAND/*DEV_ONLY[* / = "stand" /*]*/,
  VALUE/*DEV_ONLY[* / = "value" /*]*/,
  CLONE/*DEV_ONLY[* / = "clone" /*]*/,
  TITLE/*DEV_ONLY[* / = "title" /*]*/,
}

export default RefType
