import { HandlerResult } from "."
import { Ref } from "../types/refs"

export default (
  element: HTMLElement,
  handlerRef: Ref,
  root: number,
  handlers: number,
  updatable: boolean,
): HandlerResult => {
  return () => {
    window.print()
    return Promise.resolve({ remove: () => {}, update: () => {}, ids: [] })
  }
}
