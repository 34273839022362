import defined from "../utils/defined"
import element from "../refs/element"
import refs from "../helpers/refs"
import { Ref } from "../types/refs"

export default (
  parent: HTMLElement,
  data: Ref,
  options: number,
) => {
  let textTag = "p"
  const landmark = refs.get(options).get("web.landmark")
  if(defined(landmark)) textTag = landmark.getValue()
  return element(parent, data, textTag, (text, self, handleOptions) => {
    return handleOptions({
      content: content => text.innerHTML = content,
    })
  }, options)
}
