import defined from "../utils/defined"
import setters from "../setters"
import { Ref } from "../types/refs"
import handler from "../refs/handler"
import refs from "../helpers/refs"
import { HandlerResult } from "."
import setter from "../refs/setter"
// import { replaceClones } from "../refs/getter"

export default (
  element: HTMLElement,
  handlerRef: Ref,
  root: number,
  handlers: number,
  updatable: boolean,
): HandlerResult => {
  const container = document.createElement("div")
  container.className = "handler"
  if(updatable) { // TODO : check if its ok
    element.appendChild(container)
  }

  // TODO: if.else exists ?
  const thenData = handler(container, handlerRef.get("if.then"), updatable)
  const elseData = handlerRef.get("if.else")
  if(defined(elseData)) handler(container, elseData, updatable)

  return () => {
    const condition = handlerRef.get("if.condition")

    // bind getters
    let ids = new Set()
    if(updatable) {
      //if(condition.data.exists) {
        /*const ref = condition.get("exists").getValue()
        const exists = refs.root(handlers).get(ref).id
        refs.bind(exists, handlers)
        ids.add(exists)*/
        /*
        refs.add(exists, handlers)
        refs.bind(exists.id, handlers)
        ids.add(exists.id)*/
        /*const ref = refs.root(handlers).get(condition.get("exists").getValue())
        console.log(ref)*/
      //} else {
      //}
      ids = refs.bindGetters(condition.id, handlers)
    }

    let currentState: boolean

    const update = () => {
      const newState = !!setters(condition).data
      //console.log("if", condition.getValue(true), newState)
      if(newState !== currentState) {
        currentState = newState
        container.innerHTML = ""
        // FIXME: nested ifs
        if(currentState) {
          // replaceClones(elseData)
          return thenData.render()
        }
        if(defined(elseData)) return elseData.render()
      }
      return Promise.resolve(false)
    }

    return update().then((stop) => {
      return {
        stop,
        ids,
        update,
        remove: () => {
          if(currentState) {
            thenData.remove()
          } else if(defined(elseData)) {
            elseData.remove()
          }
          container.remove()
        },
      }
    })

  }
}
