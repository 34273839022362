import { Ref } from "../types/refs"
import refs from "../helpers/refs"
import RefType from "./enum"

const value = (
  data?: any,
): Ref => {
  const create = () => {
    const self: Ref =  {
      create,
      type: RefType.VALUE,
      data,

      set: (_, value) => refs.set(self.id, value),

      get: () => self,

      getValue: () => data,
    }

    return self
  }
  return create()
}

export default value

/*clone: (id) => {
  const v = value(data)
  v.id = id
  return v
},*/
