import object from "./object"
import refs from "../helpers/refs"
import defined from "../utils/defined"
import RefType from "./enum"
import { Ref } from "../types/refs"
import concat from "../utils/concat"

export default (
  parent: number,
  type: RefType,
  create: Ref["create"],
) => {
  const self = object()
  self.type = type
  self.create = create

  const objectGetter = self.get
  self.get = (ref) => {
    let offset = 0
    while((offset = ref.indexOf("<", offset)+1) != 0) {
      // TODO: deal with nested dynamic keys
      let end = ref.indexOf(">", offset)
      let subQuery = ref.substring(offset, end)
      if(!defined(self.get(subQuery))) return
      ref = ref.slice(0, offset-1) + self.get(subQuery).getValue() + ref.slice(end+1)
    }
    const first = ref.split(".", 1)[0]
    if(first === "options") {
      return refs.options(self.id).get(ref.slice(8))
    }
    if(!defined(self.data[first])) {
      return refs.get(0).get(ref)
    }
    return objectGetter(ref)
  }

  const objectSetter = self.set
  self.set = (ref, value) => {
    if(ref.startsWith("options")) {
      if(ref.charAt(7) === '.') {
        return refs.options(self.id).set(ref.slice(8), value)
      }
      if(ref.length === 7) {
        return refs.options(self.id).id
      }
    }
    return objectSetter(ref, value)
  }

  const objectIterate = self.iterate
  self.iterate = (onItem) => {
    const options = onItem(self.get("options"), "options")
    const result = objectIterate(onItem)
    if(!defined(options)) return result
    return concat(result, options)
  }

  return self
}
