import component from "../refs/component"
import button from "./button"
import checkbox from "./checkbox"
import form from "./form"
import image from "./image"
import input from "./input"
import link from "./link"
import text from "./text"
import scroll from "./scroll"
import view from "./view"
import { Ref } from "../types/refs"
import app from "../helpers/app"
import refs from "../helpers/refs"
import defined from "../utils/defined"
import RefType from "../refs/enum"
/*DEV_ONLY[* /
import logger from "../helpers/logger"
/*]*/

export default (
  parent: HTMLElement,
  data: Ref,
  child: Ref,
): Ref => {
  const key = Object.keys(child.data)[0]
  const options = refs.get(child.data[key])

  if(!defined(options)) {
    document.location.reload()
    return
    /*const nothing: Ref<any> = {
      render: () => Promise.resolve(true),
      create: () => nothing,
      type: RefType.OBJECT,
      get: () =>nothing,
      set: () => 0,
      getValue: () => {},
    }
    return nothing*/
  }

  if(key.indexOf(":") !== -1) {
    //logger.add(RefType.COMPONENT, `Composant "${key}"`, options.getValue(), undefined)
    return component(parent, data.id, app.recipes().components[key], options.id)
  }

  /*DEV_ONLY[* /logger.stack("component")/*]*/

  return (() => {
    switch(key) {
      case "Button":    return button
      case "Checkbox":  return checkbox
      case "Form":      return form
      case "Image":     return image
      case "Input":     return input
      case "Link":      return link
      case "Text":      return text
      case "Scroll":    return scroll
      case "View":      return view
    }
  })()(parent, data, options.id)
}

  /*
    children:
      - :Test: { a: 3 }

    id: :Test
    options:
      a:
        format:
          type: quantity
    children:
      Text:
        content: { $: options.a }

    id: :Test
    options:
      a:
        format:
          type: quantity
        data: 3
    children:
      Text:
        content: { $: options.a }

  */

  /* $: variables.local.component1.children.0 */
  /* ❌ { :Test: {} } */
  /* ✔️ { id: :Test, children, options, name... } */


  /*const key = Object.keys(children)[0]
  const options = children[key]
  if(key.indexOf(":") !== -1) {
    return component(root, refs.apply({
      options,
      data: root.recipes.components[key],
    }, true), parent)
  }*/
