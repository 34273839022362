import refs from "../helpers/refs"
import RefType from "./enum"
import object from "./object"
import { Ref } from "../types/refs"

export const stand = (
  ref: string,
  parent: number,
): Ref => {
  const create = () => {
    const self = object()
    self.type = RefType.STAND,
    self.create = create

    let first
    let offset = 1
    if(ref.startsWith("'")) {
      first = ref.substr(1, ref.indexOf("'", 1) - 1)
      offset = 3
    } else {
      first = ref.split(".", 1)[0]
    }

    if(first.length > 0) {
      refs.get(parent).set(first, self)
    }

    if(first.length + offset - 1 !== ref.length) {
      const rest = ref.slice(first.length + offset)
      self.data[first] = stand(rest, self.id).id
    }

    return self
  }
  return create()
}

export default stand
