import defined from "../utils/defined"
import refs from "../helpers/refs"
import value from "../refs/value"
import RefType from "../refs/enum"
import { Ref } from "../types/refs"

export default (data: Ref): Ref => {
  let result = refs.root(data.id)
  if(defined(result)) {
    result = result.get(data.getValue())
  }
  let hasValue = defined(result)
  if(hasValue) {
    hasValue = Object.keys(result.getValue()).length !== 0
  }
  return value(hasValue && result.type !== RefType.STAND)
}
