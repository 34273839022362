import i18n from "../i18n"
import component from "../refs/component"
import object from "../refs/object"
import title from "../refs/title"
import value from "../refs/value"
import { App } from "../types/context"
import { AppRecipes } from "../types/recipes"
import { Ref } from "../types/refs"
import defined from "../utils/defined"
import parse from "./parse"
import refs from "./refs"

export default (() => {
  const themeElement = document.getElementById("theme")
  const stylesElement = document.getElementById("styles")
  const renderElement = document.getElementById("render")
  //const loadingElement = document.getElementById("loading")
  if(!stylesElement || !themeElement || !renderElement) return
  //loadingElement.style.display = "inherit"

  let ready = false

  const topbar = (window as any).topbar
  if(defined(topbar)) {
    topbar.config({
      autoRun: true,
      barThickness: 3,
      shadowBlur: 10,
    })
    topbar.show()
  }

  const outsideCallbacks: { [id: number]: { element: HTMLElement, cb: () => void } } = {}
  let outsideCallbacksCurrentId = 0

  const app: App = {
    i18n: i18n(),
    navigation: {
      //route: "",
      /*update: () => {
        renderRoute(ctx.navigation.route)
      },*/
      push: (route, options) => {
        if(defined(topbar)) topbar.show()
        let path = recipes.pages[route].path
        let query = ""
        if(options) {
          Object.keys(options).forEach(id => {
            const result = encodeURIComponent(options[id])
            const param = `{${id}}`
            if(path.indexOf(param) !== -1) {
              path = path.replace(param, result)
            } else {
              query += (query === "" ? "?" : "&") + id + "=" + result
            }
          })
        }
        window.history.pushState(
          { route, options },
          recipes.pages[route].name,
          path + query,
        )
        self.render(route)
      },
    },
    //windows: {},
    events: { // TODO : refs ?
      /*resize: [],
      width: [],*/
      scroll: () => {
        console.log("scrolling")
      },
      outside: {
        add: (element, cb) => {
          outsideCallbacks[outsideCallbacksCurrentId] = { element, cb }
          return outsideCallbacksCurrentId++
        },
        remove: id => {
          delete outsideCallbacks[id]
        },
        trigger: target => {
          Object.values(outsideCallbacks).forEach(data => {
            if(target !== data.element && !data.element.contains(target)) {
              data.cb()
            }
          })
        },
      }
    },
    collections: {},
    //count: 0,
  }

  // ON BROWSER HISTORY UPDATE
  window.addEventListener("popstate", e => {
    if(defined(topbar)) topbar.show()
    self.render(e.state.route)
    window.scrollTo(0, 0)
  }, true)

  // OUTSIDE CLICKS
  document.addEventListener("click", event => {
    app.events.outside.trigger(event.target as any)
  }, true)

  let recipes: AppRecipes

  let currentPage: Ref
  let appRef: Ref

  // ON BROWSER RESIZE
  window.addEventListener("resize", () => {
    /*app.events.resize.forEach(e => e())
    app.events.width.forEach(e => e(renderElement.offsetWidth))*/
    appRef.set("width", value(renderElement.offsetWidth))
    appRef.set("height", value(renderElement.offsetHeight))
  })

  const self = {
    i18n: app.i18n,

    navigation: app.navigation,

    events: app.events,

    collections: () => app.collections,

    recipes: () => recipes,

    ready: () => ready,

    scroll: (e: any): any => {
      /*const position = e.target.scrollHeight - e.target.clientHeight
      console.log(e.target.scrollTop / position)*/
    },

    init: (newRecipes: AppRecipes) => {
      recipes = newRecipes
      if(!currentPage) {
        refs.init()
        // GLOBAL VARS
        appRef = refs.get(parse({
          width: renderElement.offsetWidth,
          project: newRecipes.project,
        }, 0, "app"))
        appRef.set("title", title())

        // LINKS
        if(defined(recipes.links)) {
          recipes.links.forEach(link => {
            const element = document.createElement("link")
            element.rel = link.rel
            element.href = link.href
            document.getElementsByTagName("head")[0].insertBefore(element, stylesElement)
          })
        }

        // STYLES
        if(defined(recipes.styles)) stylesElement.innerHTML = recipes.styles

        // THEME
        if(defined(recipes.themes)) themeElement.innerHTML = recipes.themes[0]

        // COLLECTIONS
        refs.get(0).set("collections", object())
        refs.get(0).set("collections.stores", object())
      }
      //logger.add("app", "Initialisation", newRecipes, [])
      //loadingElement.style.display = "none"
    },

    render: (route?: string) => {
      //if(defined(topbar)) topbar.show()

      let options = {}

      if(!defined(route)) { // FIST TIME ONLY
        const requestSplit = window.location.pathname.split("/")
        route = Object.keys(recipes.pages).reduce<string>((found, key) => {
          if(typeof found === "undefined") {
            const split = recipes.pages[key].path.split("/")
            if(requestSplit.length === split.length) {
              const match = split.reduce((result, current, index) => {
                if(result) {
                  if(current.startsWith("{")) {
                    options[current.slice(1, -1)] = decodeURI(requestSplit[index])
                    return true
                  }
                  return current === requestSplit[index]
                }
                return result
              }, true)
              if(match) {
                found = key
              }
            }
          }
          return found
        }, undefined)
        window.location.search.slice(1).split("&").forEach(param => {
          const [ name, value ] = param.split("=")
          options[decodeURIComponent(name)] = decodeURIComponent(value)
        })
      }

      if(!defined(route)) { // FIST TIME ONLY
        renderElement.innerHTML = "404 - page not found"
        document.title = "404"
      } else {
        const pageRecipe = recipes.pages[route]

        // TITLE
        let title = ""
        if(pageRecipe.name) title = pageRecipe.name.toString()
        document.title = title

        if(currentPage) { /*ctx.navigation.page*/
          delete refs.get(0).data.page
          options = history.state.options || {}
          //app.events.resize = []
          //root.innerHTML = ""
          //ctx.navigation.page.remove()
        } else { // HISTORY RESET ON START
          window.history.replaceState(
            { route, options },
            title,
            window.location.pathname + window.location.search,
          )
        }

        // RENDER PAGE COMPONENT
        /*ctx.navigation.page*/
        /*DEV_ONLY[* /
        console.log("Page", pageRecipe.component)
        /*]*/
        const componentOptions = parse(options, 0)
        //logger.add("page", `Page "${pageRecipe.component}"`, refs.get(componentOptions).getValue(true))
        currentPage = component(
          renderElement,
          0,
          recipes.components[pageRecipe.component],
          componentOptions,
        )
        refs.get(0).set("page", currentPage)
        currentPage.render().then(() => {
          refs.clean()
          ready = true
          if(defined(topbar)) topbar.hide()
        })
        /*DEV_ONLY[* /

        // refs.mermaid(946)
        /*]*/
      }
    }
  }

  return self
})()

/*let backupScroll
if(route === app.navigation.route) {
  backupScroll = {
    x: mainElement.scrollLeft,
    y: mainElement.scrollTop,
  }
  console.log("scroll saved :", backupScroll)
}*/

//app.navigation.route = route

/*data: {
  options: { test: "a" },
  children: [
    { Text: { content: { $: "options.test" } } },
    { Text: { content: { $: "app.global" } } },
    {
      Button: {
        content: "set to c",
        on: {
          press: [
            { set: { "options.test": "c" } },
          ],
        },
      },
    },
  ],
},*/



// function (options, data)
// page (options, data)
// recipe component (options, data)
// nested component (options)

/*const data = currentPage.get(currentPage, "data.data")
console.log(data.getValue(currentPage, ""))*/

/*
  refs(app as App).apply({
    options: { test: "a" }, // TODO : params
    data: app.recipes.components[pageRecipe.component],
  }, true),
*/

/*if(backupScroll) {
  console.log("scroll to", backupScroll)
  window.scrollTo(backupScroll.x, backupScroll.y)
}*/
