import refs from "../helpers/refs"
import handler from "../refs/handler"
import defined from "../utils/defined"
import classname from "../utils/classname"
import stand from "../refs/stand"
import object from "../refs/object"
import value from "../refs/value"
import { HandlerResult } from "."
import { Ref } from "../types/refs"
import RefType from "../refs/enum"

export default (
  element: HTMLElement,
  handlerRef: Ref,
  root: number,
  handlers: number,
  updatable: boolean,
): HandlerResult => {
  return () => {
    let ids = new Set()

    const input = handlerRef.get("iterate.input")

    if(updatable) {
      ids = refs.bindGetters(input.id, handlers)
    }

    const container = document.createElement("div")
    classname(container).move(element).add("handler")
    element.appendChild(container)

    const doVal = handler(container, handlerRef.get("iterate.do"), updatable)
    // const valueGetters = listValueGetters(doVal)

    const update = () => {
      doVal.remove()

      let keyVal = "current"
      const key = handlerRef.get("iterate.key")
      if(defined(key)) keyVal = key.getValue()

      let currentId
      let valueId
      let keyId

      if(defined(refs.get(root).get("iterate"))) {
        currentId = refs.get(root).get(`iterate.${keyVal}`).id
        valueId = refs.get(root).get(`iterate.${keyVal}.value`).id
        keyId = refs.get(root).get(`iterate.${keyVal}.key`).id
      } else {
        refs.get(root).set(`iterate`, object())
        currentId = refs.get(root).set(`iterate.${keyVal}`, object())
        valueId = stand("value", currentId).id
        keyId = stand("key", currentId).id
      }

      return input.get("").reduce((promise, current, key) => promise.then(() => {
        const cloned = refs.clone(current.id, valueId)
        delete cloned.id
        refs.set(valueId, cloned, false)
        // refs.set(valueId, current, false)
        refs.set(keyId, value(key), false)
        return doVal.render()
      }), Promise.resolve()).then(() => {
        delete refs.get(root).data.iterate
        return { ids, update, remove: doVal.remove }
      })
    }

    return update()
  }
}

const listValueGetters = (ref: Ref) => {
  const result = {
    values: [],
    keys: [],
  }
  if(defined(ref.iterate)) {
    ref.iterate(value => {
      const sub = listValueGetters(value)
      result.values.push(...sub.values)
      result.keys.push(...sub.keys)
    })
    return result
  } 
  if(ref.type === RefType.GETTER) {
    // TODO: check keyval
    if(ref.data.startsWith("iterate.")) {
      result.values.push(ref.id)
    } 
    const keys = []
    let start = 0
    while((start = ref.data.indexOf("<", start)) != -1) {
      if(ref.data.slice(start+1).startsWith("iterate.")) {
        const end = ref.data.indexOf(">", start)
        keys.push({ start, end })
      }
    }
    if(keys.length > 0) {
      result.keys.push({ id: ref.id, keys })
    }
  }
  return result
}
