import { DataSingular } from "./data"
import { Text, TextLanguage } from "./text"

export enum i18nArticle {
  FR_IND, // Français - Indéfini (un, une, des)
  FR_DEF, // Français - Défini (le, l', la, les)
  FR_DEF_CNT, // Français - Défini contracté (au, à l', à la, aux)
  FR_PAR, // Français - Partitif (du, de l', de la, des)
}

export type i18nDataQuery = {
  $: {
    type: string
    name: string
    options: any
  }
}

export type i18nData =
  | DataSingular
  | Text
  | Text[]
  | i18nDataQuery
  | { [name: string]: Text | Text[] | i18nDataQuery }

export type i18nOptions<Vars = { [name: string]: string }> = {
  count?: number
  vars?: Vars
  lowercase?: boolean
  article?: i18nArticle
  language?: TextLanguage
}

export type i18nMarkdownCompilerOptions = { [index: string]: string }

export type i18nMarkdownCompiler<Output = any> = {
  bold: (id: string, children: Output, options: i18nMarkdownCompilerOptions) => Output
  link: (id: string, link: string, children: Output, options: i18nMarkdownCompilerOptions) => Output
}

type i18nSettingsMarkdownString = {
  text: string
  options: i18nMarkdownCompilerOptions
  bold?: boolean
  link?: string
}

export type i18nSettingsMarkdownObject = {
  [index: number]: { end: number, value: i18nSettingsMarkdownString }
}

export type i18nSettingsCompilerCallback = (
  match: RegExpExecArray,
  children?: i18nSettingsMarkdownString,
) => i18nSettingsMarkdownString
