import request from "../utils/request"

export default (
  onInit: (send: (method: string, path: string, body?: any) => void) => void,
  onMessage: (response: any) => void,
) => {
  //WebSocket = undefined // DEBUG ONLY
  if(WebSocket) {
    const session = new WebSocket("ws://web.apps.dropin.local:8080")

    const send = (method, path, body) => {
      session.send(JSON.stringify({ method, path, body }))
    }

    session.onopen = e => {
      onInit(send)
    }

    session.onmessage = e => {
      onMessage(JSON.parse(e.data))
    }

    session.onclose = event => {
      console.log("session closed")
    }

    session.onerror = (error: any) => {
      console.log("error", error.message)
    }
  } else {
    onInit((method, path, body) => {
      request(method, "http://web.apps.dropin.local:8080" + path).then(body => {
        onMessage({ body })
      })
    })
  }
}
